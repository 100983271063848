export interface Point {
    x: number;
    y: number;
}

export function debounce<T extends (...args: any[]) => void>(func: T, delay: number) {
    let timeoutId: number;

    return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
        clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}