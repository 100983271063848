import axios from "axios";
import { Fruit } from "../domains/trees/trees";

export interface Grove {
    id: number,
    title: string,
}

export interface GetHomeResponse {
    pinnedFruits: Fruit[],
    totalFruitsHarvested: number,
    totalFruits: number,
    totalTrees: number,
    groves: Grove[],
}

export interface Board {
  id: number,
  title: string,
}

export interface Note {
  id: number,
  boardId: string,
  text: string,
  created: number,
  updated?: number,
  archived?: number,
  reviewed?: number,
  boardName: string,
  tags: string[],
  reminders: Reminder[],
  shareLink?: string,
}

export interface Reminder {
  id: number;
  noteId: number;
  reminder: string;
  at: number,
  repeated?: 'daily' | 'weekly' | 'monthly' | 'yearly';
  created: number;
  dismissed: number;
}

export interface GetNotesResponse {
  boards: Board[],
  notes: Note[],
  tags: string[],
}

export interface GetNoteViewResponse {
  note: Note,
  boards: Board[],
  tags: string[],
}

export class ViewService {
  public static async getHome(token: string): Promise<GetHomeResponse> {
    return (await axios.get(`${process.env.REACT_APP_API_URL}/home`, { headers: { authorization: `Bearer ${token}` } })).data;
  }

  public static async getNotes(token: string): Promise<GetNotesResponse> {
    return (await axios.get(`${process.env.REACT_APP_API_URL}/notes`, { headers: { authorization: `Bearer ${token}` } })).data;
  }

  public static async getNote(noteId: number, token: string): Promise<GetNoteViewResponse> {
    return (await axios.get(`${process.env.REACT_APP_API_URL}/note-view${noteId ? `/${noteId}` : ''}`, { headers: { authorization: `Bearer ${token}` } })).data;
  }
}