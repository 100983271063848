import React, { useState } from "react";
import { AutocompleteTextField } from 'artist-outline-components';

import styles from './multi-selector.module.scss';
import { BetterAutocompleteTextField } from "../better-autocomplete-text-field/better-autocomplete-text-field";

interface MultSelectOption {
    name: string,
}

interface Props {
    options: MultSelectOption[],
    error?: string,
    value: string[],
    onChange: (selectedOptions: string[]) => void,
}

export function MultiSelector(props: Props) {   
    const { options, error } = props;
    const selectedOptions = props.value;

    const optionSelected = (name: string) => {
        return selectedOptions.filter((option) => option === name).length > 0;
    }

    const selectOption = (name: string, selected: boolean) => {
        let newOptions = [...selectedOptions];
        if (selected) {
            if (!optionSelected(name)) {
                console.log('SELECTOPT', name, selected);
                newOptions = [...selectedOptions, name];
            }
        } else {
            newOptions = selectedOptions.filter((option) => option !== name);
        }
        props.onChange(newOptions);
    }

    function getFilteredOptions(text: string) {
        const hasCurrentOption = options.find(o => o.name === text);
        const filteredOptions = options.filter(r => r.name.includes(text));
        if (!hasCurrentOption && text.length > 0) {
            filteredOptions.unshift({ name: text });
        }
        return filteredOptions.slice(0, 3);;
    }

    return (
        <>
            <BetterAutocompleteTextField<MultSelectOption>
                className={styles['multi-input']}
                name="multi-autocomplete"
                label="start typing..."
                error={error}
                autocomplete={(value) => {
                    let matching: MultSelectOption[] = getFilteredOptions(value);
                    return Promise.resolve(matching);
                }}
                render={(value, index) => {
                    return (
                        <>
                            {value.name}{index === 0 && <span className={styles['enter-hint']}>press enter to add</span>}
                        </>
                    )
                }}
                itemSelected={(item) => {
                    selectOption(item.name, true);
                }}
                debounceTimeMS={10}
                afterInput={() => {
                    return (
                        <div className={styles.tags}>
                            {selectedOptions.length === 0 && (
                                <div className={styles['no-options']}>you haven't added any tags yet...</div>
                            )}
                            {selectedOptions.map((option) => {
                                return <div key={option} className={styles.tag}><label>{option}</label><button type="button" onClick={() => selectOption(option, false)}><span className={styles.char}>x</span></button></div>;
                            })}
                        </div>
                    );
                }}
            />
        </>
    )
}