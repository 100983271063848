import { v4 as uuidv4 } from 'uuid';
import { createContext, Dispatch, ReactNode, useContext, useEffect, useReducer, useState } from "react";
import { AuthTokenPayload, tokenKey } from './../hooks/use-auth';
import { UserService } from "../services/user-service";
import { base64UrlDecode } from "../helpers/base64";
import { AssistantService, Message } from "../services/assistant-service";

interface UserState {
    token?: string,
    tokenPayload?: AuthTokenPayload,
    emailVerified?: boolean,
    ivyMessages?: Message[],
    ivyOpen?: boolean,
    ivyContext?: 'home' | 'grove' | 'intro' | 'tutorial' | 'introCapture' | 'undefined',
    ivyThreadListener?: (message: Message, userResponse: string) => void,
}

interface Props {
    children: ReactNode,
}

const UserContext = createContext<
    { state: UserState; dispatch: Dispatch<Action> } | undefined
>(undefined);

export enum UserAction {
    SetToken = 'SET_TOKEN',
    SetEmailVerified = 'SET_EMAIL_VERIFIED',
    SetMessages = 'SET_MESSAGES',
    SetIvyOpen = 'SET_IVY_OPEN',
    SetIvyThreadListener = 'SET_IVY_THREAD_LISTENER',
    Clear = 'CLEAR',
}

type Action = {
    type: UserAction,
    payload: UserState,
}

function userReducer(state: UserState, action: Action): UserState {
    const { type, payload } = action;
    switch (type) {
        case UserAction.SetToken:
            return {
                ...state,
                ...(payload.token && { token: payload.token, tokenPayload: JSON.parse(base64UrlDecode(payload.token.split('.')[1])) }),
                ...(!payload.token && { token: undefined, tokenPayload: undefined })
            }
        case UserAction.SetEmailVerified:
            return {
                ...state,
                ...{ emailVerified: payload.emailVerified }
            }
        case UserAction.SetMessages:
            return {
                ...state,
                ...{ ivyMessages: payload.ivyMessages }
            }
        case UserAction.SetIvyOpen:
            return {
                ...state,
                ...{ ivyOpen: payload.ivyOpen, ivyContext: payload.ivyContext }
            }
            case UserAction.SetIvyThreadListener:
                return {
                    ...state,
                    ...{ ivyThreadListener: payload.ivyThreadListener }
                }
        case UserAction.Clear:
            return {};
        default:
            return state;
    }
}

export function UserProvider(props: Props) {
    const [loaded, setLoaded] = useState(false);
    const [state, dispatch] = useReducer(userReducer, {});
    const value = { state, dispatch };
    useEffect(() => {
        const token = localStorage.getItem(tokenKey);
        if (token) {
            dispatch({ type: UserAction.SetToken, payload: { token } });
        } else {
            dispatch({ type: UserAction.SetToken, payload: { token: undefined } });
        }
        setLoaded(true);
    }, []);
    useEffect(() => {
        if (loaded && !!state.token) {
            const loadMessages = async () => {
                const messages = (await AssistantService.getMessages(state.token!))!.messages;
                dispatch({
                    type: UserAction.SetMessages,
                    payload: { ivyMessages: messages }
                });
            }
            loadMessages();
        }
    }, [loaded]);
    return (
        <UserContext.Provider value={value}>{loaded && props.children}</UserContext.Provider>
    );
}

export function useUser() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
}

export function useEmailVerified(): { emailVerified: boolean | undefined, setEmailVerified: (emailVerified: boolean) => void } {
    const { state, dispatch } = useUser();
    if (state.emailVerified === undefined && state.token && state.tokenPayload?.user_id) {
        UserService.getUser(state.token).then((user) => {
            if (user) {
                dispatch({
                    type: UserAction.SetEmailVerified,
                    payload: { emailVerified: user.emailVerified }
                });
            }
        });
    }
    const setEmailVerified = (emailVerified: boolean) => {
        dispatch({
            type: UserAction.SetEmailVerified,
            payload: { emailVerified: emailVerified }
        });
    }
    return { emailVerified: state.emailVerified, setEmailVerified: setEmailVerified };
}

export function useIvy() {
    const { state, dispatch } = useUser();
    const refreshMessages = async () => {
        const messages = (await AssistantService.getMessages(state.token!))!.messages;
        dispatch({
            type: UserAction.SetMessages,
            payload: { ivyMessages: messages }
        });
    }
    const displayPromptMessage = async (message: Message) => {
        const messages = (await AssistantService.getMessages(state.token!))!.messages;
        dispatch({
            type: UserAction.SetMessages,
            payload: { ivyMessages: [...messages, message] }
        });
    }
    const setIvyOpen = async (status: boolean, context: 'home' | 'grove' | 'intro' | 'tutorial' | 'introCapture' | undefined) => {
        dispatch({
            type: UserAction.SetIvyOpen,
            payload: { ivyOpen: status, ivyContext: context }
        });
    }
    const completeMessage = async (message: Message, userResponse: string) => {
        await AssistantService.completeMessage(message.id, userResponse, state.token!);
        if (state.ivyThreadListener) {
            state.ivyThreadListener(message, userResponse);
        }
    }
    const displayMessage = async (type: string, data: any) => {
        let setThreadListener;
        if (data.threadId === undefined) {
            data.threadId = uuidv4();
            console.log("new thread", data.threadId);
            setThreadListener = (listener: (message: Message, userResponse: string) => void) => {
                dispatch({
                    type: UserAction.SetIvyThreadListener,
                    payload: {
                        ivyThreadListener: (message: Message, userResponse: string) => {
                            if (message.data.threadId === data.threadId) {
                                listener(message, userResponse);
                            }
                        }
                    }
                });
            }
        }
        await displayPromptMessage({id: -1, data: data, type: type });
        return setThreadListener;
    }
    const requestMessage = async (type: string, data: any) => {
        let setThreadListener;
        if (data.threadId === undefined) {
            data.threadId = uuidv4();
            console.log("new thread", data.threadId);
            setThreadListener = (listener: (message: Message, userResponse: string) => void) => {
                dispatch({
                    type: UserAction.SetIvyThreadListener,
                    payload: {
                        ivyThreadListener: (message: Message, userResponse: string) => {
                            if (message.data.threadId === data.threadId) {
                                listener(message, userResponse);
                            }
                        }
                    }
                });
            }
        }
        await AssistantService.requestMessage(type, data, state.token!);
        await refreshMessages();
        return setThreadListener;
    }
    return { messages: state.ivyMessages, refreshMessages: refreshMessages, ivyOpen: state.ivyOpen, setIvyOpen, displayMessage, requestMessage, completeMessage, ivyContext: state.ivyContext }
}

// thread event dispatcher