import axios from "axios";

export class TagsService {
  public static async updateTag(oldName: string, name: string, token: string): Promise<void> {
    try {
      const data = { oldName, name };
      return (await axios.post(`${process.env.REACT_APP_API_URL}/tags/update`, data, { headers: { authorization: `Bearer ${token}` } })).data;
    } catch (e) {
      return;
    }
  }

  public static async archiveTag(name: string, token: string): Promise<void> {
    try {
      const data = { name };
      await axios.post(`${process.env.REACT_APP_API_URL}/tags/archive`, data, { headers: { authorization: `Bearer ${token}` } });
    } catch (e) {
      return;
    }
  }

  // public static async deleteGrove(id: number, token: string) {
  //   try {
  //     return (await axios.delete(`${process.env.REACT_APP_API_URL}/grove/delete/${id}`, { headers: { authorization: `Bearer ${token}` } }));
  //   } catch (e) {
  //     return;
  //   }
  // }

  // public static async getNote(noteId: number, token: string) {
  //   try {
  //     return (await axios.get(`${process.env.REACT_APP_API_URL}/notes/${noteId}`, { headers: { authorization: `Bearer ${token}` } })).data;
  //   } catch (e) {
  //     return [];
  //   }
  // }
}