import React from "react";
import { ReactNode, useEffect, useRef, useState } from "react";
import { TextField } from "artist-outline-components";

import styles from './better-autocomplete-text-field.module.scss';

interface Props<T> {
    className?: string,
    name: string,
    label: string,
    error?: string,
    autocomplete: (value: string) => Promise<T[]>,
    render: (item: T, index: number) => ReactNode,
    itemSelected: (item: T) => void,
    debounceTimeMS?: number,
    afterInput?: () => ReactNode,
}

export const BetterAutocompleteTextField = <T,>(props: Props<T>) => {
    const { autocomplete, debounceTimeMS } = props;
    const [value, setValue] = useState('');
    const timeout = useRef<NodeJS.Timeout | null>(null);
    const [matchingItems, setMatchingItems] = useState<T[]>([]);
    const [active, setActive] = useState(false);
    const wrapRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (timeout.current != null) {
            clearTimeout(timeout.current);
        }
        timeout.current = global.setTimeout(() => {
            setMatchingItems([]);
            autocomplete(value || '').then((response) => {
                setMatchingItems(response);
            });
            timeout.current = null;
        }, debounceTimeMS || 300);
    }, [value]);

    function selectItem(item: T) {
        props.itemSelected(item);
        setValue('');
    }

    let above = false;
    if (wrapRef.current) {
        const wrapTop = wrapRef.current.getBoundingClientRect().top;
        const windowMiddle = window.innerHeight / 2;
        console.log("WRAP TOP", wrapTop);
        console.log("WINDOW MIDDLE", windowMiddle);
        if (wrapTop > windowMiddle) {
            console.log("ABOVE");
            above = true;
        }
    }

    return (
        <div ref={wrapRef} className={styles['autocomplete-wrap']}
            onFocus={() => {
                setActive(true);
            }}
            onBlur={(event: any) => {
                const currentTarget = event.currentTarget;
                global.setTimeout(() => {
                    if (!(currentTarget.contains(document.activeElement))) {
                        setActive(false);
                    }
                }, 0);
            }}
        >
            <TextField
                className={props.className}
                name={props.name}
                label={props.label}
                error={props.error}
                afterInput={props.afterInput}
                value={value}
                onChange={(name, value) => setValue(value)}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                        if (matchingItems.length > 0) {
                            selectItem(matchingItems[0]);
                        }
                    }
                }}
            />
            {active && <div className={[styles.panel, above ? styles.above : undefined].join(' ')}>
                <ul>
                    {matchingItems?.map((item, index) => {
                        return (
                            <li key={index}>
                                <button type="button" onClick={(event) => {
                                    event?.preventDefault();
                                    selectItem(item);
                                }
                                }>
                                    {props.render(item, index)}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </div>}
        </div>
    )
}