import axios from 'axios';
import { UserAction, useUser } from '../contexts/user-context';

export interface AuthTokenPayload {
    user_id: number,
    is_admin: boolean,
}

export const tokenKey = 'tk';
export type UseAuthReturn = [isAuthenticated: boolean | undefined, login: (email: string, password: string) => Promise<boolean>, logout: () => void, payload: AuthTokenPayload | undefined, token: string | undefined, updateToken: (token: string) => void, isAdmin: boolean | undefined];
export function useAuth(): UseAuthReturn {
    const {state, dispatch} = useUser();

    const getTokenFromStorage = (): string | undefined => {
        return localStorage.getItem(tokenKey) || undefined;
    }

    function isAuthenticated() {
        if (state.token)
            return true;
        else if (getTokenFromStorage())
            return true;
        else
            return false;
    }

    const login = async (email: string, password: string): Promise<boolean> => {
        try {
            const token = (await axios.post(`${process.env.REACT_APP_API_URL}/auth/`, {email: email, password: password})).data;
            dispatch({type: UserAction.SetToken, payload: {token}});
            saveTokenToStorage(token);
            return true;
        } catch (error) {
            alert(`${process.env.REACT_APP_API_URL}/auth/`);
            alert(error);
            console.log(error);
            return false;
        }
    }

    const logout = () => {
        dispatch({type: UserAction.Clear, payload: {}});
        clearTokenFromStorage();
    }

    const saveTokenToStorage = (token: string) => {
        localStorage.setItem(tokenKey, token);
    }

    const clearTokenFromStorage = () => {
        localStorage.removeItem(tokenKey);
    }

    const updateToken = (token: string) => {
        dispatch({type: UserAction.SetToken, payload: {token}});
        saveTokenToStorage(token);
    }

    return [isAuthenticated(), login, logout, state.tokenPayload, state.token, updateToken, state.tokenPayload?.is_admin];
}