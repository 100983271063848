import { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import styles from './takeover.module.scss';

interface Props {
    children?: ReactNode,
    className?: string,
    open: boolean,
    onClick?: () => void,
}

export function Takeover(props: Props) {
    const { children, className, open } = props;
    const takeoverRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const takeover = takeoverRef.current;
        if (takeover) {
            if (open) {
                takeover.style.top = 'calc(0% - 0px)';
            } else {
                takeover.style.top = '100%';
            }
        }
    }, [open]);

    return (<>
        {open && createPortal(<div ref={takeoverRef} onClick={() => props.onClick && props.onClick()} className={[styles.takeover, className].join(' ')}>
            {children}
        </div>, document.body)}
    </>)
}