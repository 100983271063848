
import { Button, TextArea, TextField } from 'artist-outline-components';
import { useState, useEffect, useRef } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/use-auth';
import { GetNotesResponse, Note, ViewService } from '../../../services/view-service';
import { Drawer } from '../../drawer/drawer';
import { Header } from '../../header/header';
import { Loader } from '../../loader/loader';
import { NotesService } from '../../../services/notes-service';

import styles from './notes.module.scss';

import bbImg from './../../../images/bb.png';
import downArrowIcon from './../../../images/down.svg';
import filterIcon from './../../../images/filter.svg';
import reminderIcon from './../../../images/reminder.svg';
import closeIcon from './../../../images/close.svg';
import addIconSvg from './../../../images/add-icon.svg';
import { AutocompleteSingleSelect, SingleSelectOption } from '../../autocomplete-single-select/autocomplete-single-select';
import useTouchHandler from '../../../hooks/use-touch-handler';
import { AssistantService } from '../../../services/assistant-service';
import { MultiSelector } from '../../multi-selector/multi-selector';
import { Takeover } from '../../takeover/takeover';

export function NotesView() {
    const [isAuthenticated, , , , token] = useAuth();
    const history = useHistory();
    const [data, setData] = useState<GetNotesResponse>();
    const [loading, setLoading] = useState(true);
    const [adding, setAdding] = useState(false);
    const [editing, setEditing] = useState<number>();
    const [noteText, setNoteText] = useState('');
    const [noteBoard, setNoteBoard] = useState<string>('~');
    const [noteTags, setNoteTags] = useState<string[]>([]);
    const viewRef = useRef<HTMLDivElement>(null);
    const [editingFilters, setEditingFilters] = useState(false);
    const [filterByBoard, setFilterByBoard] = useState<string>();
    const [ivyNotesOpen, setIvyNotesOpen] = useState(false);
    const [ivyNoteOpen, setIvyNoteOpen] = useState(false);
    const [promptText, setPromptText] = useState('');
    const [creativeMindset, setCreativeMindset] = useState(false);
    const [ivyResponse, setIvyResponse] = useState<string>();
    const [onlyAdditions, setOnlyAdditions] = useState(true);
    const [loadData, setLoadData] = useState(true);
    const [showScrollDown, setShowScrollDown] = useState(false);
    const [ivyLoading, setIvyLoading] = useState(false);
    const [showCategory, setShowCategory] = useState(false);
    const [showTags, setShowTags] = useState(false);
    const [filterByTags, setFilterByTags] = useState<string[]>([]);
    const holdRef = useRef<number>();
    const [voicePrompt, setVoicePrompt] = useState(false);
    const [recording, setRecording] = useState(false);
    const streamRef = useRef<MediaStream>();
    const recorder = useRef<MediaRecorder>();
    const audioChunks = useRef<any[]>([]);
    const [ivyResponding, setIvyResponding] = useState(false);
    const audioElementRef = useRef<HTMLMediaElement>();
    const [unlocked, setUnlocked] = useState(false);

    useEffect(() => {
        if (loadData) {
            setLoading(true);
            const getData = async () => {
                const data = await ViewService.getNotes(token!);
                if (data) {
                    setData(data);
                    setTimeout(() => {
                        const notesView = localStorage.getItem('notes-view');
                        if (notesView && notesView.length > 0) {
                            const notesViewData = JSON.parse(notesView);
                            setFilterByBoard(notesViewData.filterByBoard);
                            viewRef.current!.scrollTo(0, notesViewData.viewScrollTop);
                        } else {
                            document.querySelector('#bottom-of-notes')?.scrollIntoView();
                        }
                    }, 0);
                }
                setLoading(false);
                setLoadData(false);
            }
            getData();
        }
    }, [loadData]);

    let boardOptions: SingleSelectOption[] = [];
    if (data) {
        boardOptions = [
            { displayName: 'Auto Detect', name: '~', description: '' },
            ...data.boards.map(b => {
                return { displayName: b.title, name: b.title, description: '' };
            })
        ];

        if (noteBoard && !boardOptions.find(o => o.name === noteBoard)) {
            boardOptions.unshift({ displayName: noteBoard, name: noteBoard, description: '' });
        }
    }

    let filteredNotes = data?.notes || [];
    if (filterByBoard) {
        filteredNotes = filteredNotes.filter(n => n.boardName === filterByBoard);
    }

    const { handleTouchStart, handleTouchMove, handleTouchEnd } = useTouchHandler((note) => { setEditing(note.id); setNoteText(note.text); setNoteBoard(note.boardName); setNoteTags(note.tags); }, () => { }, 10);

    const tags = data?.tags.map(t => { return { name: t } }) || [];

    if (filterByTags.length > 0) {
        filteredNotes = filteredNotes.filter(n => n.tags.find(t => filterByTags.find(f => f === t)));
    }

    useEffect(() => {
        const view = viewRef.current;
        function onScroll() {
            const current = view!.scrollTop + view!.offsetHeight;
            const max = view!.scrollHeight;
            if (current < max - 100) {
                setShowScrollDown(true);
            } else {
                setShowScrollDown(false);
            }
        }

        if (view) {
            view.addEventListener('scroll', onScroll, { passive: true });
        }

        return () => {
            if (view) {
                view.removeEventListener('scroll', onScroll);
            }
        }
    });

    useEffect(() => {
        if (recording) {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                console.log("getUserMedia supported.");
                navigator.mediaDevices
                    .getUserMedia(
                        {
                            audio: true,
                        },
                    )
                    .then((stream) => {
                        const mediaRecorder = new MediaRecorder(stream);
                        audioChunks.current = [];
                        mediaRecorder.ondataavailable = (e) => {
                            console.log("DO IS YOU HAPPEN?");
                            audioChunks.current.push(e.data);
                            setRecording(false);
                        };
                        mediaRecorder.start();
                        streamRef.current = stream;
                        recorder.current = mediaRecorder;
                    })
                    .catch((err) => {
                        console.error(`The following getUserMedia error occurred: ${err}`);
                    });
            } else {
                console.log("getUserMedia not supported on your browser!");
            }
        } else {
            if (voicePrompt && recorder.current) {
                if (audioChunks.current.length > 0) {
                    const audioBlob = new Blob(audioChunks.current, { type: "audio/mp3" });
                    const fd = new FormData();
                    fd.append('file', audioBlob);
                    AssistantService.askIvyAudio(fd, token!).then((response) => {
                        console.log('I GOTTA', response);
                        const blob = new Blob([response], { type: "audio/mp3" });
                        const audio = audioElementRef.current!;
                        audio.src = window.URL.createObjectURL(blob);
                        audio.play();
                        console.log('AT THE END');
                        setIvyLoading(false);
                        setIvyResponding(true);
                        audio.onended = () => {
                            setIvyResponding(false);
                        }
                        // setVoicePrompt(false);
                        // setIvyNotesOpen(true);
                        // setIvyResponse(responseText);
                    });
                    recorder.current = undefined;
                    audioChunks.current = [];
                }
            }

            if (streamRef.current) {
                streamRef.current.getTracks().forEach(function (track) {
                    track.stop();
                });
            }
        }
    }, [recording]);


    useEffect(() => {
        if (!unlocked) {
            var AudioContext = window.AudioContext || (window as any).webkitAudioContext;
            var context = new AudioContext();
    
            const unlock = () => {
                if (context.state === 'suspended') {
                    console.log("unlocking");
                    var buffer = context.createBuffer(1, 1, 22050);
                    var source = context.createBufferSource();
                    source.buffer = buffer;
                    source.connect(context.destination);
                    source.start(0);
                    setUnlocked(true);
                    console.log("unlocked");
                } else {
                    console.log('Audio context already unlocked');
                    setUnlocked(true);
                }
            };
    
            const handleClick = () => {
                console.log('User interaction detected, attempting to unlock audio context');
                unlock();
                window.removeEventListener('click', handleClick);
                window.removeEventListener('touchstart', handleClick);
            };
    
            if (context.state === 'suspended') {
                window.addEventListener('click', handleClick);
                window.addEventListener('touchstart', handleClick);
            } else {
                console.log('Audio context already running');
                setUnlocked(true);
            }
    
            return () => {
                window.removeEventListener('click', handleClick);
                window.removeEventListener('touchstart', handleClick);
            };
        }
    }, [unlocked]);
    


    return <>
        {!isAuthenticated && <Redirect to="/login" />}
        <Loader loading={loading}></Loader>
        <div className={styles.view} ref={viewRef}>
            <Header />

            <div className={styles.notes}>
                {data && filteredNotes
                    .sort((a, b) => new Date(a.updated || a.created).getTime() - new Date(b.updated || b.created).getTime())
                    .map(note => (
                        <div
                            key={note.text}
                            className={styles.note}
                            // onClick={() => { setEditing(note.id); setNoteText(note.text); setNoteBoard(note.boardName); setNoteTags(note.tags); }}
                            onClick={() => { localStorage.setItem('notes-view', JSON.stringify({ filterByBoard, viewScrollTop: viewRef.current!.scrollTop })); history.push('/note/' + note.id); }}
                            onTouchStart={e => handleTouchStart(e, note)}
                            onTouchEnd={handleTouchEnd}
                            onTouchMove={handleTouchMove}
                        >
                            <div className={styles.info}>
                                <div className={styles.timestamp}>{formatDateForDisplay(new Date(note.updated || note.created))}</div>
                                <div className={styles.board}>{note.boardName}</div>
                            </div>
                            <div className={styles['content-and-buttons']}>
                                <div className={styles.text}>{note.text}</div>
                                <div className={styles.buttons}>
                                </div>
                            </div>
                            <div className={styles.tags}>
                                {note.tags.map(t => (
                                    <div key={t} className={styles.tag}>#{t}</div>
                                ))}
                            </div>
                            <div className={styles.reminders}>
                                {note.reminders.map(r => (
                                    <div key={r.reminder + r.at} className={styles.reminder}>
                                        <img src={reminderIcon} alt="Reminder Icon" />
                                        {r.reminder} ({formatDateForDisplay(new Date(r.at))})
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                <div id="bottom-of-notes" style={{ height: '0px', margin: '0px', padding: '0px' }}></div>
            </div>
            {data?.notes.length === 0 && <div className={styles.nonotes}>a place for thoughts</div>}
            <Button className={[styles.scrolldown, showScrollDown ? styles.show : undefined].join(' ')} type="primary" onClick={() => document.querySelector('#bottom-of-notes')?.scrollIntoView({ behavior: 'smooth' })}><img src={downArrowIcon} /></Button>
            <Drawer className={styles['main-drawer']} open={!adding}>
                <div className={styles.controls}>
                    <Button className={styles.filter} onClick={() => setEditingFilters(true)}><img src={filterIcon} /></Button>
                    <Button type='primary' onClick={() => setAdding(true)}><img src={addIconSvg} /></Button>
                    <button className={styles.ivy} onClick={() => setIvyNotesOpen(true)} onContextMenu={e => e.preventDefault()} onPointerDown={() => {
                        var audio = document.createElement("audio");
                        audioElementRef.current = audio;
                        document.body.appendChild(audio);
                        
                        if (holdRef.current) {
                            window.clearTimeout(holdRef.current);
                        }
                        holdRef.current = window.setTimeout(() => {
                            if (!voicePrompt) {
                                console.log('HOLD');
                                setVoicePrompt(true);
                                setRecording(true);
                                if (holdRef.current) {
                                    window.clearTimeout(holdRef.current);
                                }
                            }
                        }, 700);
                    }} onPointerUp={() => {
                        if (holdRef.current) {
                            window.clearTimeout(holdRef.current);
                        }
                    }}> </button>
                </div>
            </Drawer>
            <Drawer open={adding} className={styles.add} onClose={() => setTimeout(() => { setAdding(false); setNoteText(''); setNoteBoard('~'); setNoteTags([]); setShowCategory(false); setShowTags(false); }, 100)}>
                <div className={styles.wrap}>
                    <div className={styles.top}>
                        <div className={styles.title}>note</div>
                        <div className={styles.mid}></div>
                        <Button className={styles.archive} onClick={async () => {
                            setLoading(true);
                            NotesService.createNote(noteText, noteBoard!, noteTags, token!).then(async noteId => {
                                if (noteId) {
                                    history.push('/note/' + noteId);
                                }
                            });
                        }}>fullscreen editor</Button>
                    </div>
                    <form onSubmit={async (event) => {
                        event.preventDefault();
                        if (noteText.length > 3) {
                            // setLoading(true);
                            if (adding) {
                                setLoading(true);
                                NotesService.createNote(noteText, noteBoard!, noteTags, token!).then(async noteId => {
                                    if (data && noteId) {
                                        setLoadData(true);
                                    }
                                });
                            }
                            setNoteText('');
                            setNoteBoard('~');
                            setNoteTags([]);
                            setAdding(false);
                            setShowCategory(false);
                            setShowTags(false);
                            // setLoading(false);
                        }
                    }}>
                        <div className={styles.column}>
                            <TextArea
                                name="noteText"
                                label=''
                                rows={6}
                                value={noteText}
                                onChange={(_name, value) => setNoteText(value)}
                                className={styles['hide-underput']}
                            ></TextArea>
                            {showCategory && <AutocompleteSingleSelect
                                name="stream"
                                label="category"
                                value={noteBoard || ''}
                                options={boardOptions}
                                onChange={(boardName) => {
                                    setNoteBoard(boardName);
                                }}
                                className={styles['hide-underput']}
                            />}
                            {showTags && <div className={styles['hide-underput']}><MultiSelector options={tags} value={noteTags} onChange={(newTags) => setNoteTags(newTags)} /></div>}
                            <div className={styles.minis}>
                                {!showCategory && <Button type='no-bg' onClick={() => setShowCategory(true)}><img src={addIconSvg} /> category</Button>}
                                {!showTags && <Button type='no-bg' onClick={() => setShowTags(true)}><img src={addIconSvg} /> tags</Button>}
                            </div>
                            <div className={styles.buttons}>
                                <Button type="primary" buttonType="submit" className={styles['save-button']}>save</Button>
                                <Button type="primary" className={styles.frwt} onClick={() => {
                                    setLoading(true);
                                    NotesService.createNote(noteText, noteBoard!, noteTags, token!).then(async noteId => {
                                        if (noteId) {
                                            history.push('/note/' + noteId + '?bb=true');
                                        }
                                    });
                                }}> </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Drawer>
            <Drawer open={ivyNotesOpen} className={styles.ivy} onClose={() => setTimeout(() => { setIvyNotesOpen(false); setIvyResponse(undefined); setPromptText(''); }, 100)}>
                <div className={styles.wrap}>
                    <div className={styles.top}>
                        <div className={styles.title}>ask <img src={bbImg} /></div>
                    </div>
                    <div className={styles.bb}>
                        <div className={[styles.block, ivyLoading ? styles.blockloading : undefined].join(' ')}>
                            <div className={styles.circle}></div>
                            <div className={styles.detail}></div>
                            {ivyLoading && <div className={styles.loading}></div>}
                        </div>
                    </div>
                    {ivyResponse === undefined &&
                        <form onSubmit={async (event) => {
                            event.preventDefault();
                            if (promptText.length > 3) {
                                setIvyLoading(true);
                                AssistantService.askIvy(promptText, token!).then(async response => {
                                    if (data && response) {
                                        setIvyResponse(response);
                                        setIvyLoading(false);
                                    }
                                });
                            }
                        }}>
                            <div className={styles.column}>
                                <TextArea
                                    name="promptText"
                                    label='what can I help with?'
                                    rows={6}
                                    value={promptText}
                                    onChange={(_name, value) => setPromptText(value)}
                                ></TextArea>
                                <Button type="primary" buttonType="submit" className={styles['save-button']} disabled={ivyLoading}>send</Button>
                            </div>
                        </form>
                    }
                    {ivyResponse &&
                        <div className={styles.column}>
                            <TextArea
                                name="response"
                                label='response'
                                rows={6}
                                value={ivyResponse}
                            ></TextArea>
                            <Button onClick={() => {
                                setIvyLoading(true);
                                NotesService.createNote(ivyResponse, noteBoard!, [], token!).then(async noteId => {
                                    if (data && noteId) {
                                        console.log("NOTE ID", noteId);
                                        const note = await NotesService.getNote(noteId, token!);
                                        console.log("I GOT THE NOTE", note);
                                        setData({ ...data, notes: [...(data.notes), note] });
                                        setIvyLoading(false);
                                        setIvyResponse(undefined);
                                        setPromptText('');
                                    }
                                });
                            }} className={styles['ai-button']}>save as note</Button>
                            <div className={styles.row}>
                                <Button type="primary" className={styles['save-button']} onClick={() => { setIvyResponse(undefined); }}>retry</Button>
                                <Button type="primary" className={styles['save-button']} onClick={() => { setIvyResponse(undefined); setPromptText(''); }}>start over</Button>
                            </div>
                        </div>
                    }
                </div>
            </Drawer>
            <Drawer
                className={styles['filter-drawer']}
                open={editingFilters}
                fitContent={true}
                openDir='lr'
                onClose={() => setEditingFilters(false)}
            >
                <div className={styles.top}>
                    <div className={styles.title}>filters</div>
                    <div className={styles.mid}></div>
                    <button className={styles.close} onClick={() => setEditingFilters(false)}><img src={closeIcon} /></button>
                </div>
                <div className={styles.scrollarea}>
                    <div className={styles.subtitle}>filter by category</div>
                    <div className={styles.boards}>
                        <Button type={!filterByBoard ? 'primary' : 'no-bg'} onClick={() => { setFilterByBoard(undefined); setTimeout(() => { document.querySelector('#bottom-of-notes')?.scrollIntoView(); JSON.stringify({ filterByBoard, filterByTags, viewScrollTop: viewRef.current!.scrollTop }); }, 0); }}>All Categories ({data?.notes.length})</Button>
                        {data && data.boards.map(b => <Button type={filterByBoard === b.title ? 'primary' : 'no-bg'} onClick={() => { setFilterByBoard(b.title); setFilterByTags([]); setTimeout(() => { document.querySelector('#bottom-of-notes')?.scrollIntoView(); JSON.stringify({ filterByBoard, viewScrollTop: viewRef.current!.scrollTop }); }, 0); }}>{b.title} ({data?.notes.filter(n => n.boardName === b.title).length})</Button>)}
                    </div>
                    <div className={styles.subtitle}>filter by tags</div>
                    <div className={styles.tags}>
                        <Button type={filterByTags.length === 0 ? 'primary' : 'no-bg'} onClick={() => { setFilterByTags([]); setTimeout(() => { document.querySelector('#bottom-of-notes')?.scrollIntoView(); JSON.stringify({ filterByBoard, filterByTags, viewScrollTop: viewRef.current!.scrollTop }); }, 0); }}>Any Tags</Button>
                        {data && data.tags.filter(t => data?.notes.filter(n => n.tags.find(nt => nt === t) && (!filterByBoard || n.boardName === filterByBoard)).length > 0).map(t => <Button type={filterByTags.find(f => t === f) ? 'primary' : 'no-bg'} onClick={() => {
                            if (!filterByTags.find(f => t === f)) {
                                setFilterByTags([...filterByTags, t]);
                            } else {
                                setFilterByTags([...filterByTags.filter(f => f !== t)]);
                            }
                            setTimeout(() => {
                                document.querySelector('#bottom-of-notes')?.scrollIntoView();
                                JSON.stringify({ filterByBoard, viewScrollTop: viewRef.current!.scrollTop });
                            }, 0);
                        }}>{t} ({data?.notes.filter(n => n.tags.find(nt => nt === t) && (!filterByBoard || n.boardName === filterByBoard)).length})</Button>)}
                    </div>
                </div>
            </Drawer>
            <Takeover open={voicePrompt} onClick={() => {
                if (recording) {
                    recorder.current?.stop();
                    setIvyLoading(true);
                    if (streamRef.current) {
                        streamRef.current.getTracks().forEach(function (track) {
                            track.stop();
                        });
                        streamRef.current = undefined;
                    }
                    var audio = document.createElement("audio");
                    audioElementRef.current = audio;
                    document.body.appendChild(audio);
                } else if (ivyResponding) {
                    audioElementRef.current?.remove();
                    setIvyResponding(false);
                } else if (!ivyLoading) {
                    setRecording(true);
                }
            }}>
                <div className={styles.bb}>
                    <div className={[styles.block, ivyLoading ? styles.blockloading : undefined].join(' ')}>
                        <div className={styles.circle}></div>
                        <div className={styles.detail}></div>
                        {ivyLoading && <div className={styles.loading}></div>}
                    </div>
                </div>
                <div className={[styles.recorddot, recording ? styles.recording : undefined].join(' ')}></div>
                <div className={styles.instruct}>{recording ? `press anywhere to finish speaking` : ivyResponding ? `press anywere to skip` : !ivyLoading ? `press anywhere to start speaking` : 'thinking...'}</div>
                <Button className={styles.bclose} type="no-bg" onClick={() => {
                    if (recording) {
                        recorder.current?.stop();
                        setIvyLoading(true);
                    } else if (ivyResponding) {
                        audioElementRef.current?.remove();
                        setIvyResponding(false);
                    } else if (!ivyLoading) {
                        setRecording(true);
                    }
                    setVoicePrompt(false);
                    if (streamRef.current) {
                        streamRef.current.getTracks().forEach(function (track) {
                            track.stop();
                        });
                        streamRef.current = undefined;
                    }
                }}><img src={closeIcon} /></Button>
            </Takeover>
        </div>
    </>;
}

export function formatDateForDisplay(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
    };

    const formattedDate = date.toLocaleString('en-US', options)
        .replace(',', '')
        .replace(/(\d+)\/(\d+)\/(\d+),? (\d+):(\d+):(\d+)(.*)/, '$3/$1/$2 $4:$5:$6$7');

    return formattedDate;
}