import { Button, TextField } from "artist-outline-components";

import styles from './autocomplete-single-select.module.scss'
import { useEffect, useRef, useState } from "react";
import { BetterAutocompleteTextField } from "../better-autocomplete-text-field/better-autocomplete-text-field";

export interface SingleSelectOption {
    displayName: string,
    name: string,
    description: string,
}

interface Props {
    name: string,
    label: string,
    value: string,
    options: SingleSelectOption[],
    onChange: (value: string, element?: HTMLDivElement) => void,
    className?: string,
    error?: string,
}

export function AutocompleteSingleSelect(props: Props) {
    const { name, label, value, options, onChange, error } = props;
    const [focusNext, setFocusNext] = useState(false);
    const wrapRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (focusNext) {
            wrapRef.current?.querySelector('input')?.focus();
            setFocusNext(false);
        }
    }, [focusNext, setFocusNext]);

    function getFilteredOptions(text: string) {
        const hasCurrentOption = options.find(o => o.name === text);
        const filteredOptions = options.filter(r => r.name.includes(text));
        if (!hasCurrentOption) {
            filteredOptions.unshift({ displayName: 'Add new board: ' + text, name: text, description: '' });
        }
        return filteredOptions;
    }

    return <div ref={wrapRef} className={props.className}>
        {!value?.at(0) && <div className={styles['field']}><BetterAutocompleteTextField<SingleSelectOption>
            name={name}
            label={label}
            autocomplete={async text => text.length > 0 ? getFilteredOptions(text) : options}
            render={text => <div>
                <div className={styles['display-name']}>{text.displayName}</div>
                <div>{text.description}</div>
            </div>}
            itemSelected={value => onChange(value.name, wrapRef.current || undefined)}
            error={error}
        /></div>}
        {value?.at(0) && <div className={styles.row}>
            <TextField
                name={name}
                label={label}
                value={options.find(o => o.name === value)?.displayName}
                disabled={true}
                readOnly={true}
                wrapClassName={styles['disabled-field']}
            />
            <Button type="no-bg" onClick={() => {
                onChange('', wrapRef.current || undefined);
                setFocusNext(true);
            }}>change</Button>
        </div>}
    </div>
}