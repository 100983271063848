import { useState, useCallback } from 'react';

const useTouchHandler = (onTap: (data: any) => void, onDrag: () => void, touchThreshold = 10) => {
  const [touchStart, setTouchStart] = useState({ x: 0, y: 0, data: {} });
  const [touchEnd, setTouchEnd] = useState({ x: 0, y: 0 });

  const handleTouchStart = useCallback((e, data: any) => {
    const touch = e.touches[0];
    setTouchStart({ x: touch.clientX, y: touch.clientY, data });
  }, []);

  const handleTouchMove = useCallback((e) => {
    const touch = e.touches[0];
    setTouchEnd({ x: touch.clientX, y: touch.clientY });
  }, []);

  const handleTouchEnd = useCallback(() => {
    const touchDiffX = Math.abs(touchStart.x - touchEnd.x);
    const touchDiffY = Math.abs(touchStart.y - touchEnd.y);
    
    if (touchDiffX < touchThreshold && touchDiffY < touchThreshold) {
      onTap(touchStart.data);
    } else {
      onDrag();
    }
  }, [touchStart, touchEnd, touchThreshold, onTap, onDrag]);

  return {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd
  };
};

export default useTouchHandler;
