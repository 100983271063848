import axios from 'axios';

export interface BreakIntoStepsRequest {
  goal: string;
}

export interface BreakIntoStepsResponse {
  steps: string[];
}

export interface SplitStepRequest {
  outline: string;
  step: string;
}

export interface SplitStepResponse {
  steps: string[];
}

export interface RefineStepRequest {
  outline: string;
  step: string;
}

export interface RefineStepResponse {
  step: string;
}

export interface RefineGoalRequest {
  outline: string;
  step: string;
}

export interface RefineGoalResponse {
  goal: string;
}

export interface Message {
  id: number,
  message?: string,
  type: string,
  data: any,
  sent?: number,
  completed?: number,
  completedResponse?: string,
}

interface GetMessagesResponse {
  messages: Message[],
}

interface CompleteMessageRequest {
  id: number,
  userResponse: string,
}

export class AssistantService {
  public static async breakIntoSteps(goal: string, token: string): Promise<BreakIntoStepsResponse | undefined> {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/assistant/break-into-steps`, { goal }, { headers: { authorization: `Bearer ${token}` } });
      return response.data as BreakIntoStepsResponse;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  public static async splitStep(outline: string, step: string, token: string): Promise<SplitStepResponse | undefined> {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/assistant/split-step`, { outline, step }, { headers: { authorization: `Bearer ${token}` } });
      return response.data as SplitStepResponse;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  public static async refineStep(outline: string, step: string, token: string): Promise<RefineStepResponse | undefined> {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/assistant/refine-step`, { outline, step }, { headers: { authorization: `Bearer ${token}` } });
      return response.data as RefineStepResponse;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  public static async refineGoal(outline: string, goal: string, token: string): Promise<RefineGoalResponse | undefined> {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/assistant/refine-goal`, { outline, goal }, { headers: { authorization: `Bearer ${token}` } });
      return response.data as RefineGoalResponse;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  public static async requestMessage(type: string, data: any, token: string): Promise<number | undefined> {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/assistant/request-message`, { type, data }, { headers: { authorization: `Bearer ${token}` } });
      return response.data as number;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  public static async getMessages(token: string): Promise<GetMessagesResponse | undefined> {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/assistant/messages`, { headers: { authorization: `Bearer ${token}` } });
      return response.data as GetMessagesResponse;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  public static async completeMessage(id: number, userResponse: string, token: string): Promise<undefined> {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/assistant/complete-message`, { id, userResponse }, { headers: { authorization: `Bearer ${token}` } });
      return;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  public static async dismissMessage(id: number, token: string): Promise<undefined> {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/assistant/dismiss-message`, { id }, { headers: { authorization: `Bearer ${token}` } });
      return;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  public static async askIvy(prompt: string, token: string): Promise<string | undefined> {
    try {
      return (await axios.post(`${process.env.REACT_APP_API_URL}/assistant/ask-ivy`, { prompt }, { headers: { authorization: `Bearer ${token}` } })).data.responseText;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  public static async askIvyNote(prompt: string, noteText: string, creativeMindset: boolean, responseFormat: string, token: string, noteId?: number): Promise<string | undefined> {
    try {
      return (await axios.post(`${process.env.REACT_APP_API_URL}/assistant/ask-ivy-note`, { prompt, noteId, noteText, creativeMindset, responseFormat }, { headers: { authorization: `Bearer ${token}` } })).data.responseText;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  public static async askIvyAudio(fd: FormData, token: string): Promise<any | undefined> {
    try {
      return (await axios.post(`${process.env.REACT_APP_API_URL}/assistant/audio`, fd, { headers: { authorization: `Bearer ${token}` }, responseType: 'arraybuffer' })).data;
    } catch (e) {
      return;
    }
  }
}
