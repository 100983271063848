import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useHistory } from 'react-router-dom';
import { AdminViewTemplate, Container, FormTextField, FormCheckbox, Button } from 'artist-outline-components';
import { useAuth } from '../../../hooks/use-auth';
import { useFirstFocus } from '../../../hooks/use-first-focus';

import styles from './signup-view.module.scss';
import { DragFruit } from '../../drag-fruit/drag-fruit';
import { createPortal } from 'react-dom';
import { Fruit } from '../../../domains/trees/trees';

import bubbleLogo from './../../../images/bubbleLogo.png';
import basketImg from './../../../images/basket.svg';
import { Point, debounce } from '../../../helpers/drag';

interface SignupForm {
    signupEmail: string,
    signupPassword: string,
    rePassword: string,
    signupCode: string,
    acceptedTerms: boolean,
}

export function SignupView() {
    const [, login] = useAuth();
    const history = useHistory();
    const [error, setError] = useState(false);
    const [register] = useFirstFocus();
    const [draggingFruit, setDraggingFruit] = useState<{ fruit: Fruit, diffFromMouse: Point, width: number, height: number }>();
    const originNode = useRef<Node>();
    const cloneNode = useRef<Node>();
    const mousePos = useRef<Point>({ x: 0, y: 0 });
    const formRef = useRef<HTMLFormElement>(null);

    const stateToPostSignupDTO = (form: SignupForm) => {
        return {
            email: form.signupEmail,
            password: form.signupPassword,
            acceptedTerms: form.acceptedTerms,
            signupCode: form.signupCode,
        }
    }

    const { handleSubmit, errors, control, getValues } = useForm<SignupForm>({
        mode: "onBlur",
    });

    const onSubmit = (form: SignupForm) => {
        const dto = stateToPostSignupDTO(form);
        axios.post(`${process.env.REACT_APP_API_URL}/signup`, dto)
            .then(async () => {
                const success = await login(form.signupEmail, form.signupPassword);
                if (success) {
                    history.push('/');
                } else {
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            });
    }

    let emailError;
    switch (errors.signupEmail?.type) {
        case 'required': { emailError = 'email is required'; break; }
        case 'validEmail': { emailError = 'must be a valid email'; break; }
    }

    let passwordError;
    switch (errors.signupPassword?.type) {
        case 'required': { passwordError = 'password is required'; break; }
        case 'minLength': { passwordError = 'password should be 8 characters or longer' }
    }

    let rePasswordError;
    switch (errors.rePassword?.type) {
        case 'required': { rePasswordError = 'repeat password is required'; break; }
        case 'match': { rePasswordError = 'does not match Password' }
    }

    let signupCodeError;
    switch (errors.signupCode?.type) {
        case 'required': { signupCodeError = 'signup code is required'; break; }
    }

    useEffect(() => {
        const handlePointerMove = (event: PointerEvent) => {
            event.preventDefault();
            mousePos.current = { x: event.clientX, y: event.clientY };
            if (cloneNode.current) {
                (cloneNode.current as HTMLDivElement).style.position = `fixed`;
                (cloneNode.current as HTMLDivElement).style.top = `${event.clientY}px`;
                (cloneNode.current as HTMLDivElement).style.left = `${event.clientX}px`;
                (cloneNode.current as HTMLDivElement).style.transform = `translate(-50%, -50%) scale(1.2)`;
                (cloneNode.current as HTMLDivElement).style.pointerEvents = `none`;
                (cloneNode.current as HTMLDivElement).style.touchAction = `none`;
                (cloneNode.current as HTMLDivElement).style.display = `block`;
            }
        };

        const debouncedHandlePointerMove = debounce(handlePointerMove, 6); // 30 times per second

        window.addEventListener('pointermove', debouncedHandlePointerMove);
        window.addEventListener('pointerdown', debouncedHandlePointerMove);

        return () => {
            window.removeEventListener('pointermove', debouncedHandlePointerMove);
            window.removeEventListener('pointerdown', debouncedHandlePointerMove);
        };
    }, []);

    return (
        <div>
            <AdminViewTemplate title="signup" showTitle={false} className={styles.signup}>
            <div className={styles.logo}><a href="https://bubblenotesapp.com/"><img src={bubbleLogo} /></a></div>
                <Container className={styles.container} title="ready, set, bubble...">
                    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                        <FormTextField
                            name="signupEmail"
                            label="email"
                            control={control}
                            error={emailError}
                            required={true}
                            validate={{ validEmail: (data: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data) }}
                            register={register}
                            autocomplete="new-username"
                        />
                        <FormTextField
                            name="signupPassword"
                            label="password"
                            type="password"
                            control={control}
                            error={passwordError}
                            hint="minimum of 8 characters"
                            required={true}
                            minLength={8}
                            autocomplete="new-password"
                        />
                        <FormTextField
                            name="rePassword"
                            label="repeat password"
                            type="password"
                            control={control}
                            error={rePasswordError}
                            required={true}
                            validate={{ match: (data: string) => data === getValues().signupPassword }}
                            autocomplete="new-password"
                        />
                        <FormTextField
                            name="signupCode"
                            label="signup code"
                            type="text"
                            control={control}
                            error={signupCodeError}
                            required={true}
                            validate={{ match: (data: string) => data.length > 0 }}
                            autocomplete="new-password"
                        />
                        <div className={styles.legal}>
                            <p>
                                <FormCheckbox
                                    name="acceptedTerms"
                                    control={control}
                                    required={true}
                                    validate={{ accepted: (value: boolean) => !!value }}
                                    defaultValue={false}
                                />
                                by creating an account you are agreeing to our <a href="https://bubblenotesapp.com/terms-of-service">Terms of Service</a> and <a href="https://bubblenotesapp.com/privacy-policy">Privacy Policy</a>.</p>
                        </div>
                        {errors.acceptedTerms &&
                            <div className={styles['terms-error']}>
                                Yyou must accept our Terms of Service and Privacy Policy to create an account
                            </div>
                        }
                        {!errors.acceptedTerms && <div className={styles['no-terms-error']}></div>}
                        <div className={styles.controls}>
                            <Button type='primary' buttonType='submit'>signup</Button>
                        </div>
                        {error &&
                            <div className={styles.error}>
                                <p>an error occurred while creating your account, please try again.</p>
                            </div>
                        }
                    </form>
                </Container>
                <div className={styles['login-here']}>
                    <NavLink to="/login">already have an account? login here</NavLink>
                </div>
            </AdminViewTemplate>
        </div>
    )
}