import axios from "axios";
import { Reminder } from "./view-service";

export interface Review {
    noteId: number,
    noteText: string,
    noteUpdated: number,
    reviewCreated: number,
    changes: {
        addedTags: Reminder[],
        removedTags: Reminder[],
        addedReminders: Reminder[],
        removedReminders: Reminder[]
    }
}

export interface UserDTO {
    email: string,
    emailVerified: boolean,
    reviews: Review[],
}

export class UserService {
    public static async getUser(token: string): Promise<UserDTO | undefined> {
        try {
            return (await axios.get(`${process.env.REACT_APP_API_URL}/user`, { headers: { authorization: `Bearer ${token}` } })).data;
        } catch (e) {
            return;
        }
    }

    public static async dismissReviews(noteId: number, token: string) {
        try {
            return (await axios.post(`${process.env.REACT_APP_API_URL}/reviews/dismiss`, { noteId }, { headers: { authorization: `Bearer ${token}` } })).data;
        } catch (e) {
            return;
        }
    }
}
