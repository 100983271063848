import { Button, TextArea, TextField } from 'artist-outline-components';
import { useState, useEffect, useRef } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/use-auth';
import { GetNoteViewResponse, GetNotesResponse, ViewService } from '../../../services/view-service';
import { Drawer } from '../../drawer/drawer';
import { Header } from '../../header/header';
import { Loader } from '../../loader/loader';
import { NotesService } from '../../../services/notes-service';

import styles from './organize.module.scss';

import bbImg from './../../../images/bb.png';
import backIcon from './../../../images/back.svg';
import dotsIconSvg from './../../../images/dots.svg';
import { AutocompleteSingleSelect, SingleSelectOption } from '../../autocomplete-single-select/autocomplete-single-select';
import { MultiSelector } from '../../multi-selector/multi-selector';
import { AssistantService } from '../../../services/assistant-service';
import { BoardsService } from '../../../services/boards-service';
import { TagsService } from '../../../services/tags-service';

export function OrganizeView() {
    const [isAuthenticated, , , , token] = useAuth();
    const history = useHistory();
    const [data, setData] = useState<GetNotesResponse>();
    const [loading, setLoading] = useState(true);
    const [noteText, setNoteText] = useState('');
    const [noteBoard, setNoteBoard] = useState<string>('~');
    const [noteTags, setNoteTags] = useState<string[]>([]);
    const viewRef = useRef<HTMLDivElement>(null);
    const [editing, setEditing] = useState(false);
    const [ivyNoteOpen, setIvyNoteOpen] = useState(false);
    const [promptText, setPromptText] = useState('');
    const [ivyResponse, setIvyResponse] = useState<string>();
    const [responseFormat, setResponseFormat] = useState('add');
    const [creativeMindset, setCreativeMindset] = useState(false);
    const [loadData, setLoadData] = useState(true);
    const [saving, setSaving] = useState(false);
    const { id } = useParams<{ id: string }>();
    const saveTimeout = useRef<number>();
    const [contentFocused, setContentFocused] = useState(false);
    const [selectionData, setSelectionData] = useState<{ start: number, end: number }>();
    const [selectedBoard, setSelectedBoard] = useState('');
    const [editingBoard, setEditingBoard] = useState<string>();
    const [archivingBoard, setArchivingBoard] = useState<string>();
    const [moveToBoard, setMoveToBoard] = useState<number>();
    const [selectedTag, setSelectedTag] = useState('');
    const [editingTag, setEditingTag] = useState<string>();
    const [archivingTag, setArchivingTag] = useState<string>();
    const [ivyLoading, setIvyLoading] = useState(false);

    useEffect(() => {
        if (loadData) {
            setLoading(true);
            const getData = async () => {
                const data = await ViewService.getNotes(token!);
                if (data) {
                    setData(data);
                }
                setLoading(false);
                setLoadData(false);
            }
            getData();
        }
    }, [loadData]);

    return <>
        {!isAuthenticated && <Redirect to="/login" />}
        <Loader loading={loading}></Loader>
        {data && <>
            <div className={styles.view} ref={viewRef}>
                <Header beforeProfile={<div className={styles.topleftbuttons}>
                    <Button type='no-bg' className={styles.back} onClick={() => history.push('/notes')}><img src={backIcon} /></Button>
                </div>} />
                <div className={styles.wrap}>
                    <div className={styles.subtitle}>categories <span className={styles.hint}>{!selectedBoard && `(select one to edit)`}</span></div>
                    <div className={styles.boards}>
                        {data && data.boards.map(b => <Button type={selectedBoard === b.title ? 'primary' : 'no-bg'} onClick={() => { setSelectedBoard(b.title); }}>{b.title} ({data?.notes.filter(n => n.boardName === b.title).length})</Button>)}
                    </div>
                    <div className={styles.row}>
                        <Button className={styles.editbutton} disabled={selectedBoard === ''} onClick={() => setEditingBoard(selectedBoard.length > 0 ? selectedBoard : undefined)}>edit category</Button>
                        <Button className={styles.editbutton} disabled={selectedBoard === ''} onClick={() => setArchivingBoard(selectedBoard.length > 0 ? selectedBoard : undefined)}>archive category</Button>
                    </div>
                    <div className={styles.subtitle}>tags <span className={styles.hint}>{!selectedTag && `(select one to edit)`}</span></div>
                    <div className={styles.boards}>
                        {data && data.tags.map(b => <Button type={selectedTag === b ? 'primary' : 'no-bg'} onClick={() => { setSelectedTag(b); }}>{b} ({data?.notes.filter(n => n.tags.find(t => t === b)).length})</Button>)}
                    </div>
                    <div className={styles.row}>
                        <Button className={styles.editbutton} disabled={selectedTag === ''} onClick={() => setEditingTag(selectedTag.length > 0 ? selectedTag : undefined)}>edit tag</Button>
                        <Button className={styles.editbutton} disabled={selectedTag === ''} onClick={async () => {
                            const confirm = window.confirm('Aore you sure you want to archive this tag? ' + selectedTag);
                            if (confirm) {
                                await TagsService.archiveTag(selectedTag, token!);
                                setArchivingBoard(undefined);
                                setLoadData(true);
                            }
                        }}>archive tag</Button>
                    </div>
                </div>
            </div>
            <Drawer className={styles['main-drawer']} open={!ivyNoteOpen}>
                <div className={styles.controls}>
                    {/* <Button type='primary' onClick={() => setEditing(true)}><img src={dotsIconSvg} /></Button> */}
                    <Button className={[styles.ivy, contentFocused ? styles.keyboard : undefined].join(' ')} onClick={(e) => {
                        setIvyNoteOpen(true);
                        const contentElement = document.querySelector('#content') as HTMLTextAreaElement;
                        if (contentElement && contentElement.selectionStart != contentElement.selectionEnd) {
                            const newSelectionData = { start: contentElement.selectionStart, end: contentElement.selectionEnd };
                            console.log('new selection data', newSelectionData);
                            setSelectionData(newSelectionData);
                        }
                    }}> </Button>
                </div>
            </Drawer>
            <Drawer open={editingBoard !== undefined} className={styles.editing} onClose={() => setTimeout(() => { setEditingBoard(undefined); }, 100)}>
                <div className={styles.top}>
                    <div className={styles.title}>editing category</div>
                    <div className={styles.mid}></div>
                </div>
                <form>
                    <div className={styles.column}>
                        <TextField name="boardName" value={editingBoard} onChange={(_name, value) => setEditingBoard(value)} />
                        <div className={styles.buttons}>
                            <Button type="primary" className={styles['close-button']} onClick={async () => {
                                setLoading(true);
                                const boardId = data?.boards.find(b => b.title === selectedBoard)?.id;
                                if (boardId) {
                                    const updated = await BoardsService.updateBoard(boardId, editingBoard!, token!);
                                    if (updated) {
                                        setLoadData(true);
                                    }
                                }
                                setEditing(false);
                                setEditingBoard(undefined);
                            }}>save</Button>
                        </div>
                    </div>
                </form>
            </Drawer>
            <Drawer open={archivingBoard !== undefined} className={styles.editing} onClose={() => setTimeout(() => { setArchivingBoard(undefined); setMoveToBoard(undefined) }, 100)}>
                <div className={styles.top}>
                    <div className={styles.title}>archiving category</div>
                    <div className={styles.mid}></div>
                </div>
                <form>
                    <div className={styles.column}>
                        <AutocompleteSingleSelect
                            name="board"
                            label="move notes to category"
                            value={moveToBoard?.toString() || ''}
                            options={data!.boards.filter(b => b.title !== archivingBoard).map(b => {
                                return {
                                    name: b.id.toString(),
                                    displayName: b.title,
                                    description: '',
                                }
                            })}
                            onChange={(boardId) => {
                                setMoveToBoard(parseInt(boardId));
                            }}
                            className={styles['hide-underput']}
                        />
                        <div className={styles.buttons}>
                            <Button type="primary" disabled={moveToBoard === undefined} className={styles['close-button']} onClick={async () => {
                                setLoading(true);
                                const boardId = data?.boards.find(b => b.title === selectedBoard)?.id;
                                if (boardId) {
                                    await BoardsService.archiveBoard(boardId, moveToBoard!, token!);
                                    setLoadData(true);
                                }
                                setArchivingBoard(undefined);
                                setEditing(false);
                                setEditingBoard(undefined);
                            }}>save</Button>
                        </div>
                    </div>
                </form>
            </Drawer>

            <Drawer open={editingTag !== undefined} className={styles.editing} onClose={() => setTimeout(() => { setEditingTag(undefined); }, 100)}>
                <div className={styles.top}>
                    <div className={styles.title}>editing tag</div>
                    <div className={styles.mid}></div>
                </div>
                <form>
                    <div className={styles.column}>
                        <TextField name="tagName" value={editingTag} onChange={(_name, value) => setEditingTag(value)} />
                        <div className={styles.buttons}>
                            <Button type="primary" className={styles['close-button']} onClick={async () => {
                                setLoading(true);
                                await TagsService.updateTag(selectedTag, editingTag!, token!);
                                setLoadData(true);
                                setEditing(false);
                                setEditingTag(undefined);
                            }}>save</Button>
                        </div>
                    </div>
                </form>
            </Drawer>
            <Drawer open={ivyNoteOpen} className={styles.ivy} onClose={() => setTimeout(() => { setIvyNoteOpen(false); setIvyResponse(undefined); setPromptText(''); }, 100)}>
                <div className={styles.wrap}>
                    <div className={styles.top}>
                        <div className={styles.title}>ask <img src={bbImg} /></div>
                    </div>
                    <div className={styles.bb}>
                        <div className={[styles.block, ivyLoading ? styles.blockloading : undefined].join(' ')}>
                            <div className={styles.circle}></div>
                            <div className={styles.detail}></div>
                            {ivyLoading && <div className={styles.loading}></div>}
                        </div>
                    </div>
                    {ivyResponse === undefined &&
                        <form onSubmit={async (event) => {
                            event.preventDefault();
                            if (promptText.length > 3) {
                                setIvyLoading(true);
                                AssistantService.askIvy(promptText, token!).then(async response => {
                                    if (data && response) {
                                        setIvyResponse(response);
                                        setIvyLoading(false);
                                    }
                                });
                            }
                        }}>
                            <div className={styles.column}>
                                <TextArea
                                    name="promptText"
                                    label='what can I help with?'
                                    rows={6}
                                    value={promptText}
                                    onChange={(_name, value) => setPromptText(value)}
                                ></TextArea>
                                <Button type="primary" buttonType="submit" className={styles['save-button']} disabled={ivyLoading}>send</Button>
                            </div>
                        </form>
                    }
                    {ivyResponse &&
                        <div className={styles.column}>
                            <TextArea
                                name="response"
                                label='response'
                                rows={6}
                                value={ivyResponse}
                            ></TextArea>
                            <Button onClick={() => {
                                setIvyLoading(true);
                                NotesService.createNote(ivyResponse, noteBoard!, [], token!).then(async noteId => {
                                    if (data && noteId) {
                                        console.log("NOTE ID", noteId);
                                        const note = await NotesService.getNote(noteId, token!);
                                        console.log("I GOT THE NOTE", note);
                                        setData({ ...data, notes: [...(data.notes), note] });
                                        setIvyLoading(false);
                                        setIvyResponse(undefined);
                                        setPromptText('');
                                    }
                                });
                            }} className={styles['ai-button']}>save as note</Button>
                            <div className={styles.row}>
                                <Button type="primary" className={styles['save-button']} onClick={() => { setIvyResponse(undefined); }}>retry</Button>
                                <Button type="primary" className={styles['save-button']} onClick={() => { setIvyResponse(undefined); setPromptText(''); }}>start over</Button>
                            </div>
                        </div>
                    }
                </div>
            </Drawer>
        </>}
    </>;
}

function formatDateForDisplay(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
    };

    const formattedDate = date.toLocaleString('en-US', options)
        .replace(',', '')
        .replace(/(\d+)\/(\d+)\/(\d+),? (\d+):(\d+):(\d+)(.*)/, '$3/$1/$2 $4:$5:$6$7');

    return formattedDate;
}

export function truncateText(text: string): string {
    const maxChars = 200;
    const maxLines = 3;
    const ellipsis = "...";

    // Split the input string into lines
    const lines = text.split('\n');

    // Truncate to the maximum number of lines
    const truncatedLines = lines.slice(0, maxLines);

    // Join the lines back into a single string
    let truncatedString = truncatedLines.join('\n');

    // Truncate to the maximum number of characters
    if (truncatedString.length > maxChars) {
        truncatedString = truncatedString.slice(0, maxChars);
    }

    // Append ellipsis if the string was truncated
    if (truncatedString.length < text.length) {
        truncatedString += ellipsis;
    }
    return truncatedString;
}
