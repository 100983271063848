import { Button, TextArea, TextField } from 'artist-outline-components';
import { useState, useEffect, useRef } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/use-auth';
import { GetNoteViewResponse, ViewService } from '../../../services/view-service';
import { Drawer } from '../../drawer/drawer';
import { Header } from '../../header/header';
import { Loader } from '../../loader/loader';
import { NotesService } from '../../../services/notes-service';

import styles from './note.module.scss';

import bbImg from './../../../images/bb.png';
import backIcon from './../../../images/back.svg';
import copyIcon from './../../../images/copy.svg';
import archiveIcon from './../../../images/archive.svg';
import shareIcon from './../../../images/share.svg';
import dotsIconSvg from './../../../images/dots.svg';
import { AutocompleteSingleSelect, SingleSelectOption } from '../../autocomplete-single-select/autocomplete-single-select';
import { MultiSelector } from '../../multi-selector/multi-selector';
import { AssistantService } from '../../../services/assistant-service';

export function NoteView() {
    const [isAuthenticated, , , , token] = useAuth();
    const history = useHistory();
    const [data, setData] = useState<GetNoteViewResponse>();
    const [loading, setLoading] = useState(true);
    const [noteText, setNoteText] = useState('');
    const [noteBoard, setNoteBoard] = useState<string>('~');
    const [noteTags, setNoteTags] = useState<string[]>([]);
    const viewRef = useRef<HTMLDivElement>(null);
    const [editing, setEditing] = useState(false);
    const [ivyNoteOpen, setIvyNoteOpen] = useState(false);
    const [promptText, setPromptText] = useState('');
    const [ivyResponse, setIvyResponse] = useState<string>();
    const [responseFormat, setResponseFormat] = useState('add');
    const [creativeMindset, setCreativeMindset] = useState(false);
    const [loadData, setLoadData] = useState(true);
    const [saving, setSaving] = useState(false);
    const { id } = useParams<{ id: string }>();
    const saveTimeout = useRef<number>();
    const [contentFocused, setContentFocused] = useState(false);
    const [selectionData, setSelectionData] = useState<{ start: number, end: number }>();
    const [ivyLoading, setIvyLoading] = useState(false);
    // I'm in the middle of making the selection prompt functionality
    // The problem now is that the Selection object appears to get reset or deleted when another input is focused
    // The solution is to save the start and end of the selection instead of the selection itself

    useEffect(() => {
        if (loadData) {
            setLoading(true);
            const getData = async () => {
                const data = await ViewService.getNote(parseInt(id), token!);
                if (data) {
                    setData(data);
                    setNoteText(data.note.text);
                    setNoteBoard(data.note.boardName);
                    setNoteTags(data.note.tags);
                    setTimeout(() => {
                        document.querySelector('#bottom-of-notes')?.scrollIntoView();
                        const urlParams = new URLSearchParams(window.location.search);
                        const bb = urlParams.get('bb');
                        if (bb === 'true') {
                            setIvyNoteOpen(true);
                        }
                    }, 0);
                }
                setLoading(false);
                setLoadData(false);
            }
            getData();
        }
    }, [loadData]);

    useEffect(() => {
        if (!loading) {
            setSaving(true);
            if (saveTimeout.current !== undefined) {
                window.clearTimeout(saveTimeout.current);
            }
            saveTimeout.current = window.setTimeout(async () => {
                const updated = await NotesService.updateNote(data!.note.id, noteText, noteBoard, noteTags, token!);
                setData({ ...data!, note: updated! });
                setSaving(false);
            }, 2000);
        }
    }, [noteText, noteBoard, noteTags]);

    const { note } = data || {};

    let boardOptions: SingleSelectOption[] = [];
    if (data) {
        boardOptions = [
            { displayName: 'Auto Detect', name: '~', description: '' },
            ...data.boards.map(b => {
                return { displayName: b.title, name: b.title, description: '' };
            })
        ];

        if (noteBoard && !boardOptions.find(o => o.name === noteBoard)) {
            boardOptions.unshift({ displayName: noteBoard, name: noteBoard, description: '' });
        }
    }

    const tags = data?.tags.map(t => { return { name: t } }) || [];

    return <>
        {!isAuthenticated && <Redirect to="/login" />}
        <Loader loading={loading}></Loader>
        <div className={styles.view} ref={viewRef}>
            <Header beforeProfile={<div className={styles.topleftbuttons}>
                <Button type='no-bg' className={styles.back} onClick={() => history.push('/notes')}><img src={backIcon} /></Button>
            </div>} />
            {note && <div className={styles.note}>
                <div className={styles.info}>
                    <div className={styles.timestamp}>{formatDateForDisplay(new Date(note.updated || note.created))} {saving ? '(saving)' : '(saved)'}</div>
                    <div className={styles.board}>{note.boardName}</div>
                </div>
                <textarea id="content" name='text' className={styles.content} value={noteText} onChange={input => setNoteText(input.target.value)} onFocus={() => { setContentFocused(true); setTimeout(() => window.scroll(0, 0), 0); }} onBlur={() => setContentFocused(false)} />
            </div>}
        </div>
        <Drawer className={styles['main-drawer']} open={!ivyNoteOpen}>
            <div className={styles.controls}>
                <Button type='primary' onClick={() => setEditing(true)}><img src={dotsIconSvg} /></Button>
                <Button className={[styles.ivy, contentFocused ? styles.keyboard : undefined].join(' ')} onClick={(e) => {
                    setIvyNoteOpen(true);
                    const contentElement = document.querySelector('#content') as HTMLTextAreaElement;
                    if (contentElement && contentElement.selectionStart != contentElement.selectionEnd) {
                        const newSelectionData = { start: contentElement.selectionStart, end: contentElement.selectionEnd };
                        console.log('new selection data', newSelectionData);
                        setSelectionData(newSelectionData);
                    }
                }}> </Button>
            </div>
        </Drawer>
        <Drawer open={editing} className={styles.editing} onClose={() => setTimeout(() => { setEditing(false); }, 100)}>
            <div className={styles.wrap}>
                <div className={styles.top}>
                    <div className={styles.title}>details</div>
                    <div className={styles.mid}></div>
                    {(data && editing) && <Button className={styles.share} onClick={async () => {
                        const confirm = window.confirm("Are you sure you want to share this note?");
                        if (confirm) {
                            setLoading(true);
                            await NotesService.shareNote(note!.id, token!);
                            setLoadData(true);
                        }
                    }}><img src={shareIcon} /></Button>}
                    {(data && editing) && <Button className={styles.archive} onClick={async () => {
                        const confirm = window.confirm("Are you sure you want to archive this note?");
                        if (confirm) {
                            setLoading(true);
                            await NotesService.archiveNote(note!.id, token!);
                            setLoading(false);
                            setEditing(false);
                            history.push('/notes');
                        }
                    }}><img src={archiveIcon} /></Button>}
                </div>
                <div className={styles.counts}>
                    <div><b>character count</b> {noteText.length}</div>
                    <div><b>word count</b> {noteText.trim().split(/\s+/).length}</div>
                </div>
                <form>
                    <div className={styles.column}>
                        <AutocompleteSingleSelect
                            name="stream"
                            label="category"
                            value={noteBoard || ''}
                            options={boardOptions}
                            onChange={(boardName) => {
                                setNoteBoard(boardName);
                            }}
                            className={styles['hide-underput']}
                        />
                        <MultiSelector options={tags} value={noteTags} onChange={(newTags) => setNoteTags(newTags)} />
                        {data?.note.shareLink && <div className={styles.sharerow}>
                            <TextField className='sharelink' name="shareLink" label='share link' value={data?.note.shareLink} disabled={true} />
                            <Button type="no-bg" onClick={() => {
                                const copyText = document.getElementById("shareLink-input") as HTMLInputElement;
                                if (copyText) {
                                    copyText.select();
                                    copyText.setSelectionRange(0, 99999);
                                    navigator.clipboard.writeText(copyText.value);
                                }
                            }}><img src={copyIcon} /></Button>
                        </div>}
                        <div className={styles.buttons}>
                            <Button type="primary" className={styles['close-button']} onClick={() => setEditing(false)}>close</Button>
                        </div>
                    </div>
                </form>
            </div>
        </Drawer>
        <Drawer open={ivyNoteOpen} className={styles.ivy} onClose={() => setTimeout(() => {
            setIvyNoteOpen(false);
            setIvyResponse(undefined);
            setPromptText('');
            if (selectionData) {
                const contentElement = document.querySelector('#content') as HTMLTextAreaElement;
                contentElement.focus();
                contentElement.setSelectionRange(selectionData.start, selectionData.end);
                setSelectionData(undefined);
            }
        }, 100)}>
            <div className={styles.wrap}>
                {/* <div className={styles.top}>
                <div className={styles.title}>ask bb</div>
                <div className={styles.mid}></div>
                {selectionData && <Button className={styles.extract} onClick={async () => {
                    const confirm = window.confirm('Are sure you want to extract the selected text into a new note?');
                    if (confirm) {
                        const extractText = noteText.substring(selectionData.start, selectionData.end);
                        const noteId = await NotesService.createNote(extractText, noteBoard, [], token!);
                        const beginningText = noteText.substring(0, selectionData.start);
                        const endText = noteText.substring(selectionData.end);
                        setNoteText(beginningText + endText);
                        setSelectionData(undefined);
                        setIvyNoteOpen(false);
                        setPromptText('');
                    }
                }}>Extract to Note</Button>}
            </div> */}
                <div className={styles.top}>
                    <div className={styles.title}>ask <img src={bbImg} /></div>
                </div>
                <div className={styles.bb}>
                    <div className={[styles.block, ivyLoading ? styles.blockloading : undefined].join(' ')}>
                        <div className={styles.circle}></div>
                        <div className={styles.detail}></div>
                        {ivyLoading && <div className={styles.loading}></div>}
                    </div>
                </div>
                {ivyResponse === undefined &&
                    <form onSubmit={async (event) => {
                        event.preventDefault();
                        if (promptText.length > 3) {
                            setIvyLoading(true);
                            AssistantService.askIvyNote(promptText, !selectionData ? noteText : noteText.substring(selectionData.start, selectionData.end), creativeMindset, responseFormat, token!, note?.id).then(async response => {
                                if (data && response) {
                                    setIvyResponse(response);
                                    setIvyLoading(false);
                                }
                            });
                        }
                    }}>
                        {!!selectionData && <div>
                            <div className={styles['selected-text']}><b>selected text: </b>{truncateText(noteText.substring(selectionData.start, selectionData.end))}</div>
                        </div>}
                        <div className={styles.column}>
                            <TextArea
                                name="promptText"
                                label='what can I help with?'
                                rows={6}
                                value={promptText}
                                onChange={(_name, value) => setPromptText(value)}
                            ></TextArea>
                            <Button type="primary" buttonType="submit" className={styles['save-button']} disabled={ivyLoading}>send</Button>
                        </div>
                    </form>
                }
                {ivyResponse &&
                    <div className={styles.column}>
                        <TextArea
                            name="response"
                            label='Response'
                            rows={6}
                            value={ivyResponse}
                        ></TextArea>
                        {!selectionData && <>
                            <Button onClick={() => {
                                setNoteText(noteText + '\n\n' + ivyResponse);
                                setIvyResponse(undefined);
                                setPromptText('');
                                setIvyNoteOpen(false);
                            }} className={styles['ai-button']}>append to note</Button>
                            <Button onClick={() => {
                                const confirm = window.confirm('Are you sure you want to replace the existing contents of your note?');
                                if (confirm) {
                                    setNoteText(ivyResponse);
                                    setIvyResponse(undefined);
                                    setPromptText('');
                                    setIvyNoteOpen(false);
                                }
                            }} className={styles['ai-button']}>replace existing note</Button>
                        </>}
                        {selectionData && <>
                            <Button onClick={() => {
                                const beginningText = noteText.substring(0, selectionData.end);
                                const endText = noteText.substring(selectionData.end);
                                setNoteText(beginningText + ivyResponse + endText);
                                setIvyResponse(undefined);
                                setPromptText('');
                                setIvyNoteOpen(false);
                            }} className={styles['ai-button']}>append to selection</Button>
                            <Button onClick={() => {
                                const confirm = window.confirm('are you sure you want to replace the existing contents of your note?');
                                if (confirm) {
                                    const beginningText = noteText.substring(0, selectionData.start);
                                    const endText = noteText.substring(selectionData.end);
                                    setNoteText(beginningText + ivyResponse + endText);
                                    setIvyResponse(undefined);
                                    setPromptText('');
                                    setIvyNoteOpen(false);
                                }
                            }} className={styles['ai-button']}>replace selection</Button>
                        </>}
                        <div className={styles.row}>
                            <Button type="primary" className={styles['save-button']} onClick={() => { setIvyResponse(undefined); }}>retry</Button>
                            <Button type="primary" className={styles['save-button']} onClick={() => { setIvyResponse(undefined); setPromptText(''); }}>start over</Button>
                        </div>
                    </div>
                }
            </div>
        </Drawer>
    </>;
}

function formatDateForDisplay(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
    };

    const formattedDate = date.toLocaleString('en-US', options)
        .replace(',', '')
        .replace(/(\d+)\/(\d+)\/(\d+),? (\d+):(\d+):(\d+)(.*)/, '$3/$1/$2 $4:$5:$6$7');

    return formattedDate;
}

export function truncateText(text: string): string {
    const maxChars = 200;
    const maxLines = 3;
    const ellipsis = "...";

    // Split the input string into lines
    const lines = text.split('\n');

    // Truncate to the maximum number of lines
    const truncatedLines = lines.slice(0, maxLines);

    // Join the lines back into a single string
    let truncatedString = truncatedLines.join('\n');

    // Truncate to the maximum number of characters
    if (truncatedString.length > maxChars) {
        truncatedString = truncatedString.slice(0, maxChars);
    }

    // Append ellipsis if the string was truncated
    if (truncatedString.length < text.length) {
        truncatedString += ellipsis;
    }
    return truncatedString;
}
