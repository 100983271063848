import axios from "axios";
import { Board } from "./view-service";

export class BoardsService {
  public static async updateBoard(id: number, title: string, token: string): Promise<Board | undefined> {
    try {
      const data = { id, title };
      return (await axios.post(`${process.env.REACT_APP_API_URL}/boards/update`, data, { headers: { authorization: `Bearer ${token}` } })).data;
    } catch (e) {
      return;
    }
  }

  public static async archiveBoard(id: number, moveToBoard: number, token: string): Promise<void> {
    try {
      const data = { id, moveToBoard };
      await axios.post(`${process.env.REACT_APP_API_URL}/boards/archive`, data, { headers: { authorization: `Bearer ${token}` } });
    } catch (e) {
      return;
    }
  }

  // public static async deleteGrove(id: number, token: string) {
  //   try {
  //     return (await axios.delete(`${process.env.REACT_APP_API_URL}/grove/delete/${id}`, { headers: { authorization: `Bearer ${token}` } }));
  //   } catch (e) {
  //     return;
  //   }
  // }

  // public static async getNote(noteId: number, token: string) {
  //   try {
  //     return (await axios.get(`${process.env.REACT_APP_API_URL}/notes/${noteId}`, { headers: { authorization: `Bearer ${token}` } })).data;
  //   } catch (e) {
  //     return [];
  //   }
  // }
}