import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import styles from './loader.module.scss';
import { Spinner } from "../spinner/spinner";

interface Props {
    children?: ReactNode,
    className?: string,
    loading: boolean,
}

export function Loader(props: Props) {
    const { children, className, loading: open } = props;
    const loaderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const loader = loaderRef.current;
        if (loader) {
            if (open) {
                loader.style.top = 'calc(0% - 0px)';
            } else {
                loader.style.top = '100%';
            }
        }
    }, [open]);

    return (<>
        {open && createPortal(<div ref={loaderRef} className={[styles.loader, className].join(' ')}>
            <Spinner />
            {children}
        </div>, document.body)}
    </>)
}