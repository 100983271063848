import { useEffect, useRef } from "react";

export function useFirstFocus(): [(instance: HTMLInputElement | HTMLTextAreaElement | null) => void] {
    const firstFocused = useRef(false);
    const firstInput = useRef<HTMLInputElement | HTMLTextAreaElement | null>();
    useEffect(() => {
        if (!firstFocused.current && firstInput && firstInput.current) {
            firstInput.current.focus();
            firstFocused.current = true;
        }
    });
    const register = (instance: HTMLInputElement | HTMLTextAreaElement | null) => {
        firstInput.current = instance;
    }
    return [register];
}