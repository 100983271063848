import { ReactNode, useEffect, useRef, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { LoginView } from './components/views/login/login-view';
import { UserProvider, useIvy } from './contexts/user-context';

import 'artist-outline-components/dist/index.css';
import styles from './app.module.scss';
import { SignupView } from './components/views/signup/signup-view';
import { HomeView } from './components/views/home/home';
import { GroveView } from './components/views/grove/grove';
import { Drawer } from './components/drawer/drawer';

import closeIcon from './images/close.svg';
import cleanIcon from './images/sweep.svg';
import focusIcon from './images/focus.svg';
import unfocusIcon from './images/unfocus.svg';
import ivyTextImg from './images/ai.png';
import { MessageRenderer } from './components/message-renderer/message-renderer';
import { Button } from 'artist-outline-components';
import { useAuth } from './hooks/use-auth';
import { NotesView } from './components/views/notes/notes';
import { NoteView } from './components/views/note/note';
import { OrganizeView } from './components/views/organize/organize';


interface InRouterProps {
    children: ReactNode,
}

const InRouter = (props: InRouterProps) => {
    const location = useLocation();
    const lastLocation = useRef('');
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (lastLocation.current !== location.pathname) {
            lastLocation.current = location.pathname;
        }
    }, [location]);
    return (
        <>
            {props.children}
        </>
    )
}

function App(): JSX.Element {
    return (
        <UserProvider>
            <div className={styles.app}>
                <BrowserRouter>
                    <InRouter>
                        <Switch>
                            <Route exact path="/" render={() => <Redirect to="/notes" />} />
                            <Route exact path="/notes" component={NotesView} />
                            <Route exact path="/note/:id" component={NoteView} />
                            <Route exact path="/organize" component={OrganizeView} />
                            <Route exact path="/login" component={LoginView} />
                            <Route exact path="/signup" component={SignupView} />
                            <Route path="*" render={() => <Redirect to="/404" />} />
                        </Switch>
                    </InRouter>
                    {/* <AuthIvyDrawer /> */}
                </BrowserRouter>
            </div>
        </UserProvider>
    );
}


export default App;

function AuthIvyDrawer() {
    const [isAuthenticated] = useAuth();
    return <>
        {isAuthenticated && <IvyDrawer />}
    </>;
}

function IvyDrawer() {
    const { messages, refreshMessages, ivyOpen, setIvyOpen, ivyContext } = useIvy();
    const notificationsBottomRef = useRef<HTMLDivElement>(null);
    const scrollPosition = useRef<number>(0);
    const [focused, setFocused] = useState(true);
    const [focusMode, setFocusMode] = useState(true);

    function setScrollPosition(to: number) {
        scrollPosition.current = to;
    }

    useEffect(() => {
        setTimeout(() => {
            notificationsBottomRef.current?.scrollIntoView({ behavior: 'auto' });
            scrollPosition.current = 0;
        }, 10);
    }, [ivyOpen]);

    useEffect(() => {
        setFocused(false);
        setTimeout(() => {
            notificationsBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
            scrollPosition.current = 0;
            setFocused(true);
        }, 50);
    }, [messages, setFocused, focusMode]);

    return (<Drawer className={[styles['ivy-drawer'], focusMode ? styles['focus-mode'] : undefined, ivyContext == 'tutorial' ? styles.tutorial : undefined].join(' ')} open={ivyOpen || false} onClose={() => setIvyOpen(false, undefined)}>
        <div className={styles.notifications}>
            <div className={styles.wrap}>
                {messages && messages.map((m, i) => {
                    return <MessageRenderer
                        className={((i === messages.length - 1 && focused) ? styles.current : undefined)}
                        key={m.id}
                        message={m}
                        showOptions={i === messages.length - 1}
                        onRefreshTrees={async () => {
                            await refreshMessages();
                            // setReloadHarvestView(true);
                        }}
                        onFinished={async (closeEditing) => {
                            if (closeEditing) {
                                // setEditing(undefined);
                            }
                            await setIvyOpen(false, undefined);
                            await refreshMessages();
                        }}
                        onUpdateEditingTitle={newTitle => {
                            // setEditingTitle(newTitle);
                        }}
                    />;
                }
                    // <div className={styles.notification}>
                    //     <div className={styles.header}>
                    //         <div className={styles.ivy}></div>
                    //         <div className={styles.time}>now</div>
                    //     </div>
                    //     <div className={styles.text}>{m.message}</div>
                    //     {m.options.length > 0 && <div className={styles.options}>
                    //         {m.options.map(o => <Button type='primary' onClick={o.onClick}>{o.text}</Button>)}
                    //     </div>}
                    // </div>
                )}
            </div>
            <div ref={notificationsBottomRef}></div>
        </div>
        {(ivyContext != 'intro' && ivyContext != 'introCapture' && ivyContext != 'tutorial') && <div className={styles.controls}>
            <div className={styles['middle-wrap']}>
                <Button type='primary' onClick={() => setIvyOpen(false, undefined)}><img src={closeIcon} /></Button>
                {/* <div className={styles.right}>
                    <Button type='light' onClick={() => setFocusMode(!focusMode)}><img src={focusMode ? focusIcon : unfocusIcon} /></Button>
                </div> */}
            </div>
        </div>}
    </Drawer>)
}