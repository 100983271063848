import { ReactNode, useEffect, useRef, useState } from "react";
import styles from './drawer.module.scss';
import { createPortal } from "react-dom";

interface Props {
    children: ReactNode,
    className?: string,
    open: boolean,
    openDir?: 'bt' | 'rl' | 'lr',
    fitContent?: boolean,
    onClose?: () => void
}

export function Drawer(props: Props) {
    const { children, className, open, fitContent } = props;
    const useOpenDir = props.openDir || 'bt';
    const drawerRef = useRef<HTMLDivElement>(null);
    const [delayedOpen, setDelayedOpen] = useState(false);

    useEffect(() => {
        setDelayedOpen(open);
        if (!open) {
            const drawer = drawerRef.current;
            if (drawer) {
                if (useOpenDir === 'bt') {
                    drawer.style.top = '100%';
                } else if (useOpenDir === 'rl') {
                    drawer.style.left = '100%';
                } else {
                    drawer.style.right = '100%';
                }
            }
        }
    }, [open]);

    const repositionDrawer = () => {
        const drawer = drawerRef.current;
        if (drawer) {
            if (delayedOpen) {
                const bottom = drawer.querySelector('.bottom');
                if (fitContent === true || fitContent === undefined) {
                    if (useOpenDir === 'bt') {
                        drawer.style.top = 'calc(100% - ' + (bottom as any).offsetTop + 'px)';
                    } else if (useOpenDir === 'rl') {
                        drawer.style.left = 'unset';
                        drawer.style.right = '0';
                    } else {
                        drawer.style.left = '0';
                    }
                } else {
                    if (useOpenDir === 'bt') {
                        drawer.style.top = 'calc(0% - 0px)';
                    } else if (useOpenDir === 'rl') {
                        drawer.style.left = 'calc(0% + 15dvw)';
                    } else {
                        drawer.style.right = 'calc(0% + 15dvw)';
                    }
                }
            } else {
                if (useOpenDir === 'bt') {
                    drawer.style.top = '100%';
                } else if (useOpenDir === 'rl') {
                    drawer.style.left = '100%';
                } else {
                    drawer.style.right = '100%';
                }
            }
        }
    };

    useEffect(() => {
        repositionDrawer();
    }, [delayedOpen]);

    useEffect(() => {
        const drawer = drawerRef.current;
        if (drawer) {
            const resizeObserver = new ResizeObserver(() => {
                repositionDrawer();
            });

            resizeObserver.observe(drawer);

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [drawerRef.current]);

    return (
        <>
            {open && createPortal(
                <>
                    {props.onClose && <div className={[styles.backclick, styles[`dir-${useOpenDir}`]].join(' ')} onClick={(e) => {
                        setTimeout(() => {
                            if (props.onClose) {
                                props.onClose();
                            }
                        }, 10);
                    }}></div>}
                    <div ref={drawerRef} className={[styles.drawer, className, styles[`dir-${useOpenDir}`]].join(' ')}>
                        {children}
                        {(fitContent === true || fitContent === undefined) && <div className={[styles.bottom, 'bottom'].join(' ')}></div>}
                    </div>
                </>, document.body
            )}
        </>
    );
}
