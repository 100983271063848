import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AdminViewTemplate, Container, FormTextField, Button } from 'artist-outline-components';
import { NavLink, Redirect } from 'react-router-dom';
import { useAuth } from '../../../hooks/use-auth';
import { useFirstFocus } from '../../../hooks/use-first-focus';
import { useQueryParams } from '../../../hooks/use-query-params';

import bubbleLogo from './../../../images/bubbleLogo.png';

import styles from './login-view.module.scss';

interface LoginForm {
    email: string,
    password: string,
}

interface Params {
    returnUrl: string,
}

export function LoginView() {
    let { returnUrl } = useQueryParams<Params>();
    const [loginFailed, setLoginFailed] = useState(false);
    const [isAuthenticated, login] = useAuth();
    const [register] = useFirstFocus();

    const attemptLogin = async (form: LoginForm) => {
        const success = await login(form.email, form.password);
        setLoginFailed(!success);
        if (!success)
            setValue('password', '');
    }

    const { handleSubmit, errors, control, setValue } = useForm<LoginForm>({
        mode: "onBlur",
    });

    return (
        <>
            {!isAuthenticated &&
                <AdminViewTemplate title="login" showTitle={false} className={styles.login}>
                    <div className={styles.logo}><a href="https://bubblenotes.app/"><img src={bubbleLogo} /></a></div>
                    <Container title="login" className={styles.container}>
                        <form onSubmit={handleSubmit(attemptLogin)}>
                            <FormTextField
                                name="email"
                                label="email"
                                control={control}
                                error={(errors.email) ? 'email is required' : undefined}
                                required={true}
                                register={register}
                                autocomplete="email"
                            />
                            <FormTextField
                                name="password"
                                label="password"
                                type="password"
                                control={control}
                                error={(errors.password) ? 'password is required' : undefined}
                                required={true}
                                autocomplete="current-password"
                            />
                            <div className={styles.controls}>
                                <Button className={styles.login} type="primary" size="regular" buttonType="submit">login</Button>
                            </div>
                        </form>
                    </Container>
                    <div className={styles.error}>{loginFailed && (<div className={styles.message}>login unsuccessful, please try again.</div>)}</div>
                    <div className={styles['signup-here']}>
                        <NavLink to="/signup">need an account? signup here (it's free!)</NavLink>
                        {/* <div><NavLink to="/request-password-reset">Forgot password?</NavLink></div> */}
                    </div>
                </AdminViewTemplate>
            }
            {isAuthenticated && <Redirect to={returnUrl || '/'}></Redirect>}
        </>
    )
}