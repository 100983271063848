import styles from './header.module.scss';

import bLogoImg from './../../images/b.png';
import closeIcon from './../../images/close.svg';
import reviewsIcon from './../../images/review.svg';
import menuIcon from './../../images/menu.svg';
import tidyIcon from './../../images/sweep.svg';
import profileIcon from './../../images/profile.svg';
import notesIcon from './../../images/notes.svg';
import reminderIcon from './../../images/reminder.svg';

import { Drawer } from '../drawer/drawer';
import { ReactNode, useEffect, useState } from 'react';
import { Button, TextField } from 'artist-outline-components';
import { useUser } from '../../contexts/user-context';
import { useAuth } from '../../hooks/use-auth';
import { Review, UserService } from '../../services/user-service';
import { useHistory } from 'react-router-dom';
import { formatDateForDisplay } from '../views/notes/notes';
import { NotesService } from '../../services/notes-service';
import { RemindersService } from '../../services/reminders-service';
import { Loader } from '../loader/loader';

interface Props {
    beforeProfile?: ReactNode,
}

export function Header(props: Props) {
    const { beforeProfile } = props;
    const history = useHistory();
    const [menuOpen, setMenuOpen] = useState(false);
    const [reviewsOpen, setReviewsOpen] = useState(false);
    const [editProfile, setEditProfile] = useState(false);
    const [, , logout, , token] = useAuth();
    const [data, setData] = useState<{ email: string, reviews: Review[] }>();
    const [skipReviews, setSkipReviews] = useState<number[]>([]);
    const [dismissedReminders, setDismissedReminders] = useState<number[]>([]);
    const [loadData, setLoadData] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loadData) {
            setLoadData(false);
            const getProfile = async () => {
                const profile = await UserService.getUser(token!);
                setData(profile);
                setLoading(false);
            }
            getProfile();
        }
    }, [loadData]);

    const r = data?.reviews.filter(r => !skipReviews.find(s => s === r.noteId))[0];

    return (
        <>
            <div className={styles.header}>
                <div className={styles.wrap}>
                    <div className={styles.logo}><img src={bLogoImg} /></div>
                    {beforeProfile}
                    <button className={styles.reviews} onClick={() => setReviewsOpen(true)}><img src={reviewsIcon} />{(data !== undefined && data.reviews.length > 0) && <div className={styles.number}>{data?.reviews.length < 100 ? data.reviews.length : '99+'}</div>}</button>
                    <button className={styles.profile} onClick={() => setMenuOpen(true)}><img src={menuIcon} /></button>
                </div>
            </div>
            <Loader loading={loading}></Loader>
            <Drawer
                className={styles['profile-drawer']}
                open={menuOpen}
                fitContent={true}
                openDir='rl'
                onClose={() => setMenuOpen(false)}>
                <div className={styles.top}>
                    <div className={styles.title}>menu</div>
                    <div className={styles.mid}></div>
                    <button className={styles.close} onClick={() => setMenuOpen(false)}><img src={closeIcon} /></button>
                </div>
                <div className={styles.menubuttons}>
                    <Button type='light' onClick={() => { history.push('/notes'); setMenuOpen(false); }}><img src={notesIcon} />notes</Button>
                    <Button type='light' onClick={() => { history.push('/organize'); setMenuOpen(false); }}><img src={tidyIcon} />tidy</Button>
                    <Button type='light' onClick={() => setEditProfile(true)}><img src={profileIcon} />profile</Button>
                </div>
            </Drawer>
            {/* <Drawer
                className={styles['profile-drawer']}
                open={organize}
                fitContent={false}
                openDir='rl'
                onClose={() => setMenuOpen(false)}>
                <div className={styles.top}>
                    <div className={styles.title}>menu</div>
                    <div className={styles.mid}></div>
                    <button className={styles.close} onClick={() => setMenuOpen(false)}><img src={closeIcon} /></button>
                </div>
                <div className={styles.menubuttons}>
                    <Button type='light' onClick={() => { history.push('/notes'); setMenuOpen(false); }}><img src={notesIcon} />notes</Button>
                    <Button type='light' onClick={() => setEditProfile(true)}><img src={profileIcon} />profile</Button>
                </div>
            </Drawer> */}
            <Drawer
                className={styles['profile-drawer']}
                open={editProfile}
                fitContent={true}
                openDir='rl'
                onClose={() => setEditProfile(false)}
            >
                <div className={styles.top}>
                    <div className={styles.title}>profile</div>
                    <div className={styles.mid}></div>
                    <button className={styles.close} onClick={() => setEditProfile(false)}><img src={closeIcon} /></button>
                </div>
                {/* <TextField
                    name='name'
                    label='name'
                    value="Josh"
                    max={35}
                /> */}
                <TextField
                    name='email'
                    label='email'
                    value={data?.email}
                    hint={`Sorry, editing your email address is not currently possible.`}
                    alwaysShowHint={true}
                    disabled={true}
                />
                <hr />
                <div className={styles.buttons}>
                    <Button onClick={() => {
                        logout();
                    }}>Logout</Button>
                </div>
            </Drawer>
            <Drawer
                className={styles['review-drawer']}
                open={reviewsOpen}
                fitContent={true}
                openDir='bt'
                onClose={() => { setReviewsOpen(false); setSkipReviews([]) }}>
                <div className={styles.wrap}>
                    <div className={styles.top}>
                        <div className={styles.title}><span className={styles.count}>{data?.reviews.filter(r => !skipReviews.find(s => s === r.noteId)).length} left</span></div>
                        <div className={styles.mid}></div>
                        <button className={styles.close} onClick={() => { setReviewsOpen(false); setSkipReviews([]) }}><img src={closeIcon} /></button>
                    </div>
                    {r && <>
                        <div className={styles.review}>
                            <div className={styles.text}>
                                <div className={styles.timestamp}>{formatDateForDisplay(new Date(r.noteUpdated))}</div>
                                {r.noteText}
                            </div>
                            <div className={styles.changes}>
                                {r.changes.addedReminders.length > 0 && <>
                                    <div className={styles.grouptitle}>Added Reminders</div>
                                    <div className={styles.reminders}>
                                        {r.changes.addedReminders?.filter(r => !dismissedReminders.find(d => d === r.id)).map(r => (
                                            <div key={r.reminder + r.at} className={styles.reminder}>
                                                <img src={reminderIcon} alt="Reminder Icon" />
                                                <div className={styles.text}>{r.reminder} ({formatDateForDisplay(new Date(r.at))})</div>
                                                <div className={styles.remove}><Button type="no-bg" onClick={() => {
                                                    const confirm = window.confirm(`are you sure you want to dismiss this reminder?`);
                                                    if (confirm) {
                                                        RemindersService.dismissReminder(r.id, token!);
                                                        setDismissedReminders([...dismissedReminders, r.id]);
                                                    }
                                                }}><img src={closeIcon} /></Button></div>
                                            </div>
                                        ))}
                                    </div>
                                </>}
                                {r.changes.removedReminders.length > 0 && <>
                                    <div className={styles.grouptitle}>Removed Reminders</div>
                                    <div className={styles.reminders}>
                                        {r.changes.removedReminders?.map(r => (
                                            <div key={r.reminder + r.at} className={styles.reminder}>
                                                <img src={reminderIcon} alt="Reminder Icon" />
                                                <div className={styles.text}>{r.reminder} ({formatDateForDisplay(new Date(r.at))})</div>
                                                <div className={styles.remove}><img src={closeIcon} /></div>
                                            </div>
                                        ))}
                                    </div>
                                </>}
                            </div>
                        </div>
                        <div className={styles.row}>
                            <Button type="light" onClick={() => setSkipReviews([...skipReviews, r.noteId])}>review this later</Button>
                            <Button type="primary" onClick={async () => { setLoading(true); await UserService.dismissReviews(r!.noteId, token!); setLoadData(true); }}>looks good</Button>
                        </div>
                    </>}
                    {!r && <>
                        <div className={styles.emptyreview}>great job</div>
                        <div className={styles.row}>
                            <Button type="primary" onClick={() => { setReviewsOpen(false); setSkipReviews([]) }}>feeling good</Button>
                        </div>
                    </>}
                </div>
            </Drawer >
        </>
    )
}