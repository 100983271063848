import axios from "axios";
import { Note } from "./view-service";

export class NotesService {
  public static async createNote(text: string, boardName: string, tags: string[], token: string): Promise<number | undefined> {
    try {
      const data = { text, boardName, tags };
      const response = (await axios.post(`${process.env.REACT_APP_API_URL}/notes/add`, data, { headers: { authorization: `Bearer ${token}` } }));
      return response.data.insertId;
    } catch (e) {
      return;
    }
  }

  public static async updateNote(id: number, text: string, boardName: string, tags: string[], token: string): Promise<Note | undefined> {
    try {
      const data = { id, text, boardName, tags };
      return (await axios.put(`${process.env.REACT_APP_API_URL}/notes/update`, data, { headers: { authorization: `Bearer ${token}` } })).data;
    } catch (e) {
      return;
    }
  }

  public static async archiveNote(id: number, token: string): Promise<Note | undefined> {
    try {
      const data = { id };
      return (await axios.post(`${process.env.REACT_APP_API_URL}/notes/archive`, data, { headers: { authorization: `Bearer ${token}` } })).data;
    } catch (e) {
      return;
    }
  }

  public static async shareNote(id: number, token: string): Promise<Note | undefined> {
    try {
      const data = { id };
      return (await axios.post(`${process.env.REACT_APP_API_URL}/notes/share`, data, { headers: { authorization: `Bearer ${token}` } })).data;
    } catch (e) {
      return;
    }
  }

  // public static async deleteGrove(id: number, token: string) {
  //   try {
  //     return (await axios.delete(`${process.env.REACT_APP_API_URL}/grove/delete/${id}`, { headers: { authorization: `Bearer ${token}` } }));
  //   } catch (e) {
  //     return;
  //   }
  // }

  public static async getNote(noteId: number, token: string) {
    try {
      return (await axios.get(`${process.env.REACT_APP_API_URL}/notes/${noteId}`, { headers: { authorization: `Bearer ${token}` } })).data;
    } catch (e) {
      return [];
    }
  }
}