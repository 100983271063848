import axios from "axios";

export class RemindersService {
  public static async dismissReminder(id: number, token: string): Promise<void> {
    try {
      const data = { id };
      await axios.post(`${process.env.REACT_APP_API_URL}/reminders/dismiss`, data, { headers: { authorization: `Bearer ${token}` } });
    } catch (e) {
      return;
    }
  }
}